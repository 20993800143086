export default {
  "actions": {
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])}
  },
  "header": {
    "horarioAtencion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon-Thu from 9:00am to 6:30pm, Fri from 9:00am to 2:00pm"])},
    "ski": {
      "groups": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Experiences"])},
        "university": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["University"])},
        "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schools"])},
        "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workers"])},
        "premium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premium"])},
        "family": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Families"])},
        "private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Private Groups"])}
      },
      "destinations": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinations"])}
      },
      "stations": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stations"])}
      }
    },
    "night": {
      "festivals": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Festivals"])}
      },
      "apres": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Après ski"])}
      }
    },
    "us": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Us"])},
      "whoWeAre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who We Are?"])},
      "work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work with Us"])}
    },
    "user": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log In"])},
      "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Up"])}
    },
    "login": {
      "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome!"])},
      "welcomePlanner": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Welcome to ", _interpolate(_named("planner")), "!"])},
      "continuarGoogle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue with Google"])},
      "tusDatos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your data"])},
      "restablecerPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset password"])}
    }
  },
  "searcher": {
    "organized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Experiences"])},
    "personalized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Experiences"])},
    "destiny": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where do you want to go?"])}
    },
    "date": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dates"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose when you can go"])}
    },
    "datesAccomodation": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accommodation Dates"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose when you want to go"])}
    },
    "datesForfait": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ski Pass Dates"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ski pass dates"])}
    },
    "rooms": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rooms"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the rooms"])}
    },
    "groups": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group Type"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where are you coming from?"])}
    },
    "resumenReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation Summary"])}
  },
  "filters": {
    "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
    "plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters"])},
    "restablecer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restore"])},
    "alojamiento": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You've run out of accommodation"])},
      "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in the following details, and in case any room becomes available, you will be notified"])}
    }
  },
  "alojamiento": {
    "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accommodation"])},
    "mejoresOfertas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Best offers"])},
    "nuestrasExperiencias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our experiences"])},
    "verTodasExperiencias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View all"])},
    "imagenes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Images"])},
    "verImagenes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See images"])},
    "eligeDistancia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the distance to slopes (m)"])},
    "eligeDistanciaPokerRed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the distance to casino (m)"])},
    "eligeTipo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the type of accommodation you'd like to go to"])},
    "eligePrecio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the price range of your trip"])},
    "galeria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gallery"])},
    "compartir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share"])},
    "seleccion_buscador": {
      "desde": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom trips"])},
      "descripcion_extras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["we give you the option to choose your extras to your liking"])}
    },
    "ordenacion": {
      "masEconomicos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cheapest"])},
      "menosEconomicos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Most expensive"])},
      "mejorValorados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Best rated"])},
      "menosValorados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Worst rated"])}
    },
    "habitaciones": {
      "singular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["room"])},
      "plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rooms"])}
    },
    "tipos": {
      "galeria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gallery"])},
      "apartamento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apartment"])},
      "hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel"])},
      "albergue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hostel"])},
      "estudio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Studio"])},
      "no aplica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not applicable"])}
    },
    "nombreTitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "filtros": {
      "titulo": {
        "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter accommodations"])},
        "reestablecer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset filters"])}
      },
      "tipos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the type of accommodation you'd like to go to"])},
      "precios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the price range of your trip"])},
      "distancia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the distance to slopes (m)"])}
    },
    "resultados": {
      "encontradoUno": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " accommodation found"])},
      "encontradosVarios": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " accommodations found"])},
      "encontradosMuchos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More than 50 trips available"])},
      "sinResultados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No results found with your search, you can try a new search."])},
      "sinResultadosFechas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We don't have any accommodations available for the selected dates. We recommend starting a new search. You can check availability within our trips, and if you don't find anything, write to us for recommendations."])}
    },
    "nuestrosViajes": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our trips"])},
      "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join our trips with your friends and meet a community of travelers like you. What are you waiting for to join the best snow experiences?"])},
      "coincidencia": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your search matches dates with one of "])},
        "verCoincidencia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See match"])},
        "seguirBuscando": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue searching"])}
      },
      "queEs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What are our trips?"])}
    },
    "filtro": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trip Filter"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter accommodations"])},
      "reestablecer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset filters"])}
    },
    "buscador": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trip Search"])}
    },
    "porPersona": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per person"])},
    "valoracion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating"])},
    "masInformacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More information about the accommodation"])},
    "diasForfait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forfait days from"])},
    "estrellas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stars"])},
    "distancia": {
      "a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To"])},
      "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["from"])}
    },
    "precio": {
      "desde": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
      "porPersona": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per person"])}
    },
    "modales": {
      "nuestrosViajes": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our trips"])},
        "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join our trips with your friends and meet a community of travelers like you. What are you waiting for to join the best snow experiences?"])},
        "coincidencia": {
          "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your search matches dates with one of our trips"])},
          "verCoincidencia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See match"])},
          "seguirBuscando": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue searching"])}
        }
      },
      "filtros": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trip Filter"])},
        "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter accommodations"])},
        "reestablecer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset filters"])},
        "tipos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the type of accommodation you'd like to go to"])},
        "poblaciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the location you would like to go to"])},
        "precios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the price range of your trip"])},
        "distancia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the distance to slopes (m)"])}
      },
      "buscador": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trip Search"])}
      }
    },
    "sinResultados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No results found with your search, you can try a new search."])},
    "sinResultadosFechas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We don't have any accommodations available for the selected dates. We recommend starting a new search. You can check availability within our trips, and if you don't find anything, write to us for recommendations."])},
    "forfait": {
      "dias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forfait days from"])},
      "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in"])},
      "al": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])},
      "distancia": {
        "a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To"])},
        "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["from the ski station"])}
      }
    },
    "lineUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmed line-up"])},
    "compartirLanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check out the trip I found!"])},
    "consigueEntrada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get your ticket"])},
    "verMas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SHOW MORE >"])},
    "verMenos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SHOW LESS >"])},
    "consigueAbono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get your pass"])},
    "todasExperiencias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See all experiences"])},
    "proximamente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coming soon..."])},
    "verFoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See all photos"])},
    "verMapa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See map"])},
    "verMapaAlojamientos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEE ACCOMMODATION MAP"])},
    "verUbicaciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See locations"])},
    "servicios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
    "nombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accommodation name"])},
    "ofertas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offers"])},
    "preguntasFrecuentes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
    "horariosCondiciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Special schedules and conditions"])},
    "checkIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-in"])},
    "checkOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-out"])},
    "distancias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distances"])},
    "accesoPistas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slope access"])},
    "accesoPokerRed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poker Red access"])},
    "puntoInteres": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Points of interest"])},
    "descripcionAlojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accommodation description"])},
    "regimenDisponibles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available regimes and room types"])},
    "deEstacionEsqui": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["from the ski station"])},
    "cancelacionGratis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FREE Cancellation"])},
    "parcialmenteReembolsable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partially refundable"])},
    "otrasOpcionesViaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other experience options"])},
    "servicioHotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel services"])},
    "ubicacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
    "fechaAlojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accommodation date"])},
    "filtrosAlojamientos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accommodation Filter"])}
  },
  "home": {
    "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "banner": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LIVE THE COMPLETE EXPERIENCE"])},
      "titleBsspain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YOUR SALSA AND BACHATA EVENTS"])},
      "titlePr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BOOK YOUR POKER TRIPS PREFLOP"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join our experiences with your friends and meet a community of travelers like you. Select a destination, the dates you'd like to travel, and your departure point. What are you waiting for to join the best snow experiences?"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experience Recommender"])},
      "eligeFecha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose when to travel"])},
      "experienciasAnyo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experiences 24/25"])},
      "experiencias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experiences"])},
      "eligeTuEvento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose your event"])},
      "experienciasAMedida": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom experiences"])}
    },
    "festivales": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OUR EXPERIENCES"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travel with your friends to our favorite destinations where you can enjoy our two main events: AWS Formi and WSF Pas de la Casa."])},
      "titleDefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our experiences"])},
      "subtitleDefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover unique experiences designed to connect communities through travel and events"])},
      "titleBsspain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose your event"])},
      "subtitleBsspain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salsa, bachata or mixed in any date or city"])},
      "titlePr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upcoming tournaments"])},
      "subtitlePr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travel with your best friends to the best poker festivals in the country and always stay close to the casino"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dates"])},
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])}
    },
    "buscadorSection": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create your custom experience"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't our group trips fit your needs? Use our search tool to configure a fully customized trip."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to the search tool"])}
    },
    "porqueReservar": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why book with Ski&Night?"])},
      "skiEnjoy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SKI, ENJOY"])},
      "sleep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLEEP, "])},
      "repeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REPEAT"])},
      "texto1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organize your snow trip to any destination in Europe."])},
      "texto2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" are the perfect destinations to enjoy the snow and the best nightlife."])},
      "texto3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At Ski&Night, we offer you a unique experience in the best destinations, right in the middle of a natural paradise. We provide you with"])},
      "texto4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" and access to festivals so that all you have to worry about is enjoying yourself."])},
      "alojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["accommodation, lessons"])},
      "material": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["equipment rental"])}
    },
    "packs": {
      "school": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School trips"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you are between 5 and 17 years old and want to make the most of the skiing days, this is your trip. Improve your technique and enjoy nature; we provide you with transportation, accommodation, and instructors."])}
      },
      "family": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Family trips"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We offer you the most comfortable accommodation for traveling to the snow with your family. You can also book ski lessons for children and many other activities adapted to your needs."])}
      },
      "university": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["University trips"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is your trip if you want to meet people your age at the best festivals and après-skis. Do you dare to live an unforgettable experience in the snow?"])}
      },
      "company": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work trips"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traveling with your work colleagues is easy. If you are over 25 years old, join our organized trips to meet people like you, where skiing and nightlife will surprise you."])}
      },
      "premium": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premium trips"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book an exclusive trip to the best accommodations in the snow and complement your skiing days with unique experiences in the snow: gourmet, relaxation, extreme sports, or cultural experiences."])}
      }
    },
    "festival": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Winter Snow Festival, our festival"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At Ski&Night, we have designed the Winter Snow Festival experience, our snow festival where you can enjoy the most exclusive DJs and musicians of the moment in the middle of the mountain."])},
      "description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This year, we celebrate the 4th edition of the WSF, a unique experience in the snow that you can enjoy in Andorra with more than 5,000 people like you."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check our festivals"])}
    },
    "apres": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The best après-skis"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are specialists in organizing the best parties in the snow. After spending the day in the snow, you can enjoy great music from our DJs in an incomparable environment. We present you with a selection of the most exclusive après-skis:"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See all"])}
    },
    "experiencias": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The best experiences"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View all"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])}
    },
    "offers": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The best offers"])}
    },
    "stations": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The best ski resorts"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We organize the best snow travel getaways in Formigal, Grandvalira, and Sierra Nevada, among other resorts. Take a look at the resorts where we organize trips and choose the one that suits you best."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show All"])}
    },
    "form": {
      "realizarCheckin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perform check-in"])},
      "others": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
      "backButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
      "nextButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
      "tamañoMaximo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The maximum file size for upload is "])},
      "personalInformation": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal Information"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
        "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
        "expedicionDni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passport Issuance Date"])},
        "pais": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
        "welcomeBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome back"])},
        "alergias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allergies"])},
        "emailLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your email to log in or register"])},
        "dataRegister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your details to register"])},
        "emailOtherLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login with another account"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "confirmEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Email"])},
        "passwordRegister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
        "ocultar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide"])},
        "mostrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show"])},
        "password8CharReq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least 8 characters"])},
        "password1MayusReq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least 1 uppercase letter"])},
        "password1NumReq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least 1 number"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Password"])},
        "newpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Password"])},
        "confirmnewpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm New Password"])},
        "contraseniaLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your password to log in"])},
        "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
        "direccion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
        "poblacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
        "provincia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Province"])},
        "codPostal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postal Code"])},
        "birthDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of Birth"])},
        "sexo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
        "hombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male"])},
        "mujer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])},
        "nacionalidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nationality"])},
        "diaHoraLlegada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day and Time of Arrival"])},
        "horaSalida": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checkout time"])},
        "lugarNacimiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birth place"])},
        "diaLlegada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrival Day"])},
        "horaLlegada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrival Time"])},
        "anversoReversoDni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Front and Back of ID Card Uploaded"])},
        "alquilerMaterial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equipment Rental"])},
        "descripcionAlquilerMaterial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The data is indicative; in the store, you can try and exchange it for what suits you best"])},
        "altura": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Height (cm)"])},
        "ingresaAltura": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Height"])},
        "peso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight (kg)"])},
        "ingresaPeso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Weight"])},
        "tallaPie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foot Size (EU)"])},
        "ingresaTallaPie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Foot Size"])},
        "emailComercialesActivos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active Commercial Emails"])},
        "title-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to tell us more about yourself?"])},
        "write": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write here"])},
        "ADni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Front of passport"])},
        "msjADni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It must be a PDF in PNG, JPG, or GIF format"])},
        "selectedFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Document"])},
        "selecciona": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
        "RDni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back of passport"])},
        "anversoReversoDnis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Front and back of passport"])},
        "howDid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How did you hear about us?"])},
        "cv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you want, you can attach your CV"])},
        "errorPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error validating password, check credentials."])},
        "noTienesCuenta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't have an account?"])},
        "aceptoMailing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I accept the sending of updates and commercial communications."])},
        "noRecibirMailing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I do not want to receive news and commercial communications."])},
        "registrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
        "errorRegPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password must meet all security requirements."])},
        "errorEmailFormal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The email has not a valid format."])},
        "logeate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
        "dni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passport"])},
        "msjcontrasenia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For a more secure password, we recommend using a combination of uppercase and lowercase letters, numbers, and special characters such as *, %, !."])},
        "iniciarSesionRegistrarReservar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in or register to reserve"])},
        "registradoCorrect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registered Successfully"])}
      },
      "tripInformation": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travel Features"])},
        "whereFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where are you traveling from?"])},
        "howMany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How many people are you?"])},
        "whoTravel": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who are you traveling with?"])},
          "friends": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friends"])},
          "family": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Family"])},
          "couple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Couple"])},
          "work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work colleagues"])}
        },
        "places": {
          "andorra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andorra"])},
          "formigal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formigal"])},
          "sierraNevada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sierra Nevada"])},
          "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other location"])},
          "noPreference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No preference"])}
        },
        "fields": {
          "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comments"])},
          "otherStation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other Station"])},
          "others": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Others"])},
          "writeHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write here"])},
          "otherInterest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other Interest"])},
          "otherCompanion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other Companion"])}
        },
        "interests": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What interests you most?"])},
          "cousine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gastronomy"])},
          "party": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Party and atmosphere"])},
          "relax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relax and spa"])},
          "snow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snow activities"])},
          "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other interests"])}
        },
        "distance": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance to slopes"])},
          "near": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close to slopes"])},
          "noPreference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No preference"])}
        },
        "whenTravel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travel dates"])},
        "howManyDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How many days do you want to ski?"])},
        "destiny": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where do you want to go?"])},
          "noPreference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No preference, recommend me!"])}
        },
        "accommodation": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where would you prefer to stay?"])},
          "hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel"])},
          "apartment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apartment"])},
          "noPreference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I have no preference"])}
        },
        "tracks": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance to the slopes?"])},
          "near": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I want to be slope-side"])},
          "noPreference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I don't mind moving"])}
        }
      },
      "Thanks": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank You"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We will contact you in less than 48 hours!"])},
        "title-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thanks for the information. You will hear from us soon."])}
      },
      "policy": {
        "readAccept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I have read and accept the"])},
        "readAccepts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I have read and accept the"])},
        "readAcceptsLos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I have read and accept the"])},
        "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and the"])},
        "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["terms and conditions"])},
        "freePlacesPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free Places Policy"])},
        "policyCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellation Policy"])},
        "policyAlojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Occupancy Accommodation Policy"])},
        "cancelReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation Cancellation"])},
        "titleCondicionesCancelacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditions and cancellation fees"])},
        "msgCondiciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The booking conditions for the trip are as follows:"])},
        "descriptionCondiciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If the customer cancels the trip after the deposit has been paid and the reservation is considered firm and REFUNDABLE, the following sums would have to be paid:"])},
        "descriptionCondicionesNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If the customer cancels the trip after the deposit has been paid and the reservation is considered firm and NON-REFUNDABLE, the following sums would have to be paid:"])},
        "detail1Condiciones": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("porcentaje")), "% if it occurs between ", _interpolate(_named("fechaInicio")), " and before ", _interpolate(_named("fechaFin")), "."])},
        "detail2Condiciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100% if it is within 15 natural days before departure."])},
        "detail3Condiciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The reservation of the trip will not be refunded under any circumstances."])},
        "detail4Condiciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can also change the reservation holder so that a companion can replace you; this change can be made a maximum of 5 days before the start date of the trip and may have an associated cost."])},
        "detail5Condiciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The management fees associated with changes to the reservation or for non-compliance with payment deadlines will not be refundable under any circumstances."])},
        "detail1Condiciones1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["From ", _interpolate(_named("fechaInicio")), " to ", _interpolate(_named("fechaFin")), " "])},
        "detail2Condiciones1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supplements and expenses associated with modifications to the reservation or due to non-compliance with payment deadlines and fees will not be refundable in any case."])},
        "detail3Condiciones1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You can modify your reservation for free until ", _interpolate(_named("fecha")), " in the My reservations section in your profile."])},
        "detail4Condiciones1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If the customer cancels the trip after the deposit has been paid and the reservation is considered firm and NON-REFUNDABLE, the following sums would have to be paid:"])}
      }
    }
  },
  "sponsors": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sponsors and collaborators"])}
  },
  "footer": {
    "us": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who we are?"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
      "work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work with us"])},
      "blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog"])},
      "faqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQs"])}
    },
    "saleGroups": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group offers"])},
      "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School trips"])},
      "family": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Family trips"])},
      "university": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["University trips"])},
      "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company trips"])},
      "premium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premium trips"])}
    },
    "saleSki": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skiing offers"])},
      "december": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ski December"])},
      "christmas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ski Christmas"])},
      "newYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ski New Year"])},
      "holyWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ski Holy Week"])},
      "others": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other offers"])}
    },
    "terminosCondiciones": {
      "bono": {
        "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal Conditions of Sale of Services by TRAVEL&NIGHT S.L. - Tax ID B87814125 Purchasing this ticket constitutes acceptance of the legal conditions, privacy policy, and cookies, which you can review on the purchase website. This voucher is revocable at any time by the organizer by offering a refund of the amount paid. The organization sets its own legal conditions for access and sale of services, which you should consult on their media or request explicitly from them. For any claims related to the organization of the event or experience, the holder of this voucher must contact the organizer. No changes or refunds are accepted, except those authorized by the organizer. The organization reserves the right of admission and may deny or expel the enjoyment of any of the services that are part of the experience. TRAVEL&NIGHT is not responsible for any issues arising from counterfeiting, duplication, or illegal sale of this ticket. In the event of refunds, the organizer reserves the right to withhold management fees as these have been correctly processed by the platform."])}
      }
    },
    "copyright": {
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juan Hurtado de Mendoza, 17 posterior, 1º Dcha, 28036 - Madrid, Spain"])},
      "legal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal notice"])},
      "faqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQs"])},
      "cookies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookies policy"])},
      "conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General conditions"])}
    },
    "social": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow us!"])}
  },
  "us": {
    "whoWeAre": {
      "banner": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ABOUT US"])},
        "text": {
          "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are a digital platform that is revolutionizing the ski sector by designing snow experiences, both individual and group. From our beginnings, we have taken more than 30,000 travelers to all the ski resorts in Spain, Andorra, and France."])},
          "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our specialty is connecting travelers and creating communities through leisure and sports in the snow. We have the best après-ski parties and our own snow festival, the Winter Snow Festival in Andorra. In addition, we offer tailor-made trips for families, companies, schools, and gourmet experiences, where we adapt to the needs and preferences of our travelers, offering them the best in relaxation, dining, and entertainment."])}
        }
      },
      "team": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SKI&NIGHT TEAM"])}
      },
      "values": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SKI&NIGHT VALUES"])},
        "enviroment": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Young and close-knit environment"])},
          "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At Ski&Night, we are a young company with a close and flexible team, which allows us to know the product we sell very well."])},
          "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The good vibes are transmitted when creating snow experiences and in how we adapt to the needs of our travelers, whether they are university students, schools, companies, gourmet enthusiasts, or families."])}
        },
        "honesty": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Honesty and transparency"])},
          "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Honesty is one of the keys to our company, and it is our guiding principle in our interactions with both our travelers and our suppliers."])},
          "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Since our inception, over 25,000 travelers have placed their trust in us, and a high percentage of them repeat their experience with S&N, with a loyalty rate of 83.8%."])}
        },
        "compromise": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commitment and sense of belonging"])},
          "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Ski&Night brand has become synonymous with leadership and commitment, evolving from its inception into a leading brand in the snow sector."])},
          "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This commitment extends not only to our relationship with travelers but also to our relationships with suppliers, resorts, and the natural environment in which we operate."])}
        },
        "figures": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAJOR GLOBAL FIGURES"])},
          "experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YEARS OF EXPERIENCE"])},
          "travelers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRAVELERS"])},
          "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GROUP TRIPS"])},
          "collaborations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COLLABORATIONS"])}
        },
        "season": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2021/22 SEASON SUMMARY"])}
      }
    }
  },
  "porqueReservar": [
    {
      "icono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONVENIENCE IN PAYMENT"])},
      "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decide whether you prefer to pay for the whole trip or just your share and check the status in your profile."])}
    },
    {
      "icono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PERSONALIZED ATTENTION"])},
      "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our customer service staff and guides at the destination will answer all your questions."])}
    },
    {
      "icono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FLEXIBILITY IN YOUR TRIPS"])},
      "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the extras and services you need and personalize your trip as much as possible."])}
    },
    {
      "icono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TAILOR-MADE LEISURE"])},
      "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enjoy a wide range of relaxation, snow activities, gastronomy, and nightlife."])}
    }
  ],
  "viajesCatalogo": {
    "availableTrips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group trips"])},
    "placeholderSelectMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a month"])},
    "tituloFiltroPoblaciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locations"])},
    "tituloFiltroLugarExperiencia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experience Location"])},
    "tituloFiltroPrecios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prices"])},
    "tituloFiltroAgrupaciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groups"])},
    "tituloFiltroCategorias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
    "filtroPoblaciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the locations you would like to visit"])},
    "filtroPrecios": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Choose the price range (", _interpolate(_named("currency")), ") you want to search in"])},
    "filtrosFestivales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the festivals you would like to attend"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join one of these group trips and enjoy the experience with your friends. You can reserve a new room or join an existing one."])},
    "descriptionPremium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book one of our exclusive trips to the snow with relaxing, gourmet, cultural, or extreme experiences. You can also contact us directly at this phone <a href='https://wa.me/+34910054547' target='_blank'><span class='mx-2 text-primary'> +34 910054670 </span></a> and we will design a trip completely tailored to your needs."])}
  },
  "seleccionAlojamientos": {
    "1availableTrips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accommodation available"])},
    "availableTrips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accommodations available"])},
    "descriptionTrips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For your group experience, we have these accommodations available"])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See locations"])},
    "places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["accommodations"])},
    "1places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["accommodation"])},
    "ordenarPor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort by"])},
    "ordenarMenorMayor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort from lowest to highest price"])},
    "ordenarMayorMenor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort from highest to lowest price"])}
  },
  "mejoresOfertas": {
    "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The best offers"])}
  },
  "porqueReservaTitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why book with Ski and Night?"])},
  "porqueReservaTitulo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why book with Sea and Night?"])},
  "habitacionBuscador": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help us find the best price, organize your rooms"])},
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the number of rooms/apartments"])},
    "habitacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room"])},
    "adulto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adults"])},
    "ninos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Children"])},
    "nino": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Child"])},
    "edadNino": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Children's ages"])}
  },
  "viajesGrupos": {
    "todosPublicos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All audiences"])},
    "mas25anios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over 25 years old"])},
    "de18a25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["18 to 25 years old"])},
    "de0a17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0 to 17 years old"])},
    "msjPlazasLibres": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["free spaces in the room, in case they remain free a surcharge will be assumed of"])},
    "msjRepartir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for each free space to be distributed among the members of the room as a 'Rest of the accommodation'"])},
    "modificarReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "plazaLibre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free space"])},
    "invitarCorreo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can invite by email by clicking the button."])},
    "llenarInformacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in the Tell us more about you information."])},
    "faltaPoliticasPrivacidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy policies and full occupancy accommodation policy must be confirmed."])},
    "faltaPoliticasCancelacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellation policies must be confirmed."])},
    "faltaRespuestasFormulario": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All form questions must be answered."])},
    "infoSeleccionServicios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Once you have finished your booking, send the link we will provide you with to the other people so they can join your experience."])}
  },
  "reserva": {
    "misReservas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My reservations"])},
    "reactivarParaRecuperla": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click the reactivate button to recover it"])},
    "proximasReservas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your upcoming reservations"])},
    "misQRs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My QRs"])},
    "proximasNights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My upcoming tickets"])},
    "graciasReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thanks for booking"])},
    "estadoPagoReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation payment status"])},
    "informacionExperiencia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experience information"])},
    "personasHabitacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["People in the room"])},
    "resumen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summary"])},
    "tabs": {
      "personas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["People in the room"])},
      "resumen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summary"])},
      "informacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experience information"])},
      "estadoPago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment status"])},
      "comunidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Community"])}
    },
    "comunidad": {
      "invitacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Community Invitation"])},
      "unirse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join the Community"])},
      "descripcionInvitacion": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have been invited to join a community in the ", _interpolate(_named("experiencia")), " experience. Select which of your reservations you want to join with."])},
      "tusReservas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your reservations in this experience"])},
      "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create your community or join an existing one and group your reservation with others. The community allows the organizer to know who you are traveling with and consider it for transport, rooming, and other services of the experience. Communities are private; to join one, you must request the link from its creator."])},
      "creacionExitosa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Community successfully created"])},
      "boton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create community"])},
      "yaEnSupergrupo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You already belong to this super-group"])},
      "crear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create community"])},
      "miembrosComunidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Community members"])},
      "aunSinReservas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have any reservations yet"])},
      "pincha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click"])},
      "aqui": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["here"])},
      "paraComenzarReservar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to start booking"])},
      "unidoExito": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have successfully joined the community"])},
      "seleccionaGrupo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a group to join"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of your community"])},
      "pertenece": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your reservation belongs to the following community:"])},
      "compartirLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share the link so others can join your community directly:"])},
      "miembros": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Members"])},
      "miembro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member"])},
      "plazaLibre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["free spots"])},
      "confirmarEliminar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this community?"])},
      "noRecuperar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Once deleted, it cannot be recovered."])},
      "errorEliminar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error deleting the community"])},
      "errorCrear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error creating the community"])},
      "eliminacionExitosa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Community successfully deleted"])}
    },
    "pendientePago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending payment"])},
    "totalReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total reservation"])},
    "reservaEncuentra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This reservation is"])},
    "pagoPlazosDeshabilitado": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You are now up to date with your payment schedule, you can make the next payment starting on ", _interpolate(_named("fecha")), "."])},
    "miViaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My trip"])},
    "verQRs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See QRs"])},
    "reservasCancelar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the reservations you want to cancel"])},
    "mensajeCompraExp": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Click ", _interpolate(_named("aqui")), " to see all available experiences"])},
    "pago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment"])},
    "paga": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay"])},
    "totalPagado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total paid"])},
    "totalPendiente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total pending"])},
    "saldoPendiente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outstanding balance"])},
    "noReservas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have no reservations yet"])},
    "noFiestas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have no party reservations yet"])},
    "moreAbout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tell us more about yourself"])},
    "howPay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose how you want to pay"])},
    "mensajeCompra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When you make a purchase, it will appear here"])},
    "complementos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complements"])},
    "suplementos": {
      "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supplements and expenses"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supplements and expenses"])},
      "PLAZAS_LIBRES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free places"])}
    },
    "tasas": {
      "externas": {
        "TASA_TURISTICA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tourist tax"])}
      },
      "internas": {
        "TASA_GESTION_PLATAFORMA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platform management tax"])},
        "TASA_GESTION_CAMBIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change management tax"])}
      }
    },
    "tasa": {
      "externas": {
        "turistica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tourist tax"])}
      },
      "gestion-plataforma": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platform management fee"])},
      "gestion-cambio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change management fee"])}
    },
    "pagos": {
      "pago": {
        "senial": {
          "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Down payment"])}
        },
        "total": {
          "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total experience payment"])}
        },
        "viaje": {
          "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partial payment"])},
          "diferido": {
            "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deferred payment"])}
          }
        }
      }
    },
    "superGrupo": {
      "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Super-Group"])},
      "informacionTransporte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transport Information"])},
      "informacionViaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information about your trip"])},
      "fiestaContradas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hired parties"])},
      "crear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create super-group"])},
      "unirse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join super-group"])},
      "msjNoGrupoamigo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your group doesn't have any supergroup yet. Create a supergroup or join an existing one before the order date to travel on the same bus and stay in rooms as close as possible."])},
      "msjSuperGruposEdificio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supergroups will only be considered for nearby buildings due to the arrival point"])},
      "pertenceSuperGrupo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You belong to the supergroup"])},
      "msjPuntoLlegado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supergroups will only be considered for nearby buildings due to the arrival point"])}
    },
    "tipo": {
      "PC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full board"])},
      "MP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Half board"])},
      "AD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bed and breakfast"])},
      "SA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room only"])},
      "SD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Breakfast only"])}
    },
    "plazasLibres": {
      "modal": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are still free spots in your reservation!"])},
        "tituloSingular": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["There is still ", _interpolate(_named("plazasLibres")), " free spot in your reservation!"])},
        "tituloPlural": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["There are still ", _interpolate(_named("plazasLibres")), " free spots in your reservation!"])},
        "bodySingular": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["There is room for ", _interpolate(_list(0)), " more person in your reservation. A supplement of ", _interpolate(_list(1)), " will be added to each confirmed person. If the free spots are eventually filled, the supplement will be refunded."])},
        "bodyPlural": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["There is room for ", _interpolate(_list(0)), " more people in your reservation. A supplement of ", _interpolate(_list(1)), " will be added to each confirmed person. If the free spots are eventually filled, the supplement will be refunded."])},
        "addSuplemento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add supplement to the reservation"])},
        "genteReservar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["People are still missing from the reservation"])},
        "completarPlazas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill seats"])}
      }
    },
    "grupo": {
      "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])},
      "msjGrupo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group your booking with others. Groups will be taken into account for the services of the experience, for example when distributing transportation. To create a group, send the link to users of other bookings."])},
      "miembrosGrupo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group members"])}
    },
    "buses": {
      "nombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bus"])},
      "horaSalidaIda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Departure time (outbound)"])},
      "lugarSalidaIda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Departure place (outbound)"])},
      "horaSalidaVuelta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Departure time (return)"])},
      "lugarSalidaVuelta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Departure place (return)"])}
    },
    "bono": {
      "descargarBono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download voucher"])},
      "fechaIni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start date"])},
      "fechaFin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End date"])},
      "direccion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
      "informacionGeneral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General information"])},
      "checkIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check In"])},
      "checkOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check Out"])},
      "regimen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regime"])},
      "impuestosIncluidos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxes included"])},
      "incluidoPaqueteBasico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Included in basic package"])},
      "servicioExtra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra service"])},
      "localizador": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locator"])},
      "titular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Holder"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact email"])}
    },
    "checkIn": {
      "msg": {
        "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-in and personal data completed for all reservations."])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is mandatory to fill in all the details."])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred while processing the reservations."])}
      },
      "done": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Check In for ", _interpolate(_named("nombre")), " completed"])},
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check In"])},
      "alergias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allergies"])},
      "nacionalidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nationality"])},
      "fechaNacimiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of Birth"])},
      "lugarNacimiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place of Birth"])},
      "dni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID/Passport"])},
      "soporteDni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DNI support number"])},
      "fechaCaducidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expiration Date"])},
      "fechaExpedicion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Issue Date"])},
      "pais": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
      "direccion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
      "codigoPostal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postal Code"])},
      "altura": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Height"])},
      "tallaPie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shoe Size"])},
      "peso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight"])},
      "card": {
        "sinCompletar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This form is incomplete"])},
        "sinGurdar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This form is not saved"])},
        "guardado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This form has already been submitted"])},
        "completar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete"])},
        "verRespuestas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View responses"])},
        "enviar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
        "rellena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill out the form"])},
        "rellenaLos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill out the forms"])}
      },
      "error": {
        "rellenar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are incomplete forms"])},
        "formularioIncompleto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The form is not complete"])},
        "errorEnvio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error sending the form"])}
      },
      "sexo": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
        "hombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male"])},
        "mujer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])}
      },
      "llegada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrival"])},
      "salida": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Departure"])},
      "anversoDni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Front of ID/Passport"])},
      "reversoDni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back of ID/Passport"])},
      "seleccionarDocumento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Document"])},
      "placeholders": {
        "seleccionaNacionalidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a nationality"])},
        "seleccionaPais": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a country"])},
        "escribeTuRespuesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type your answer"])},
        "fecha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dd/mm/yy"])},
        "comerciales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales Agent Name"])}
      },
      "paises": {
        "españa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spain"])},
        "otro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])}
      }
    },
    "informacionViaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["more accommodation information"])},
    "msjFechaLimite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The deadline for payment or any changes to services free of charge will be before the day"])},
    "msjPagoRealizado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The payment for your trip has already been made. Stay tuned for published parties."])},
    "msjNoPagos": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You still have ", _interpolate(_named("pendientePago")), _interpolate(_named("currency")), " pending payment."])},
    "reservasDe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservations for"])},
    "mantenerReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep Reservation"])},
    "msjSolucionReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We can help you find an alternative solution if you need to make changes to your reservation"])},
    "proximosViajes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upcoming experiences:"])},
    "condicionesReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The reservation conditions for the trip are as follows"])},
    "concepto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concept"])},
    "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "precio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
    "recuperarReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recover reservation"])},
    "codViaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is the code for your trip"])},
    "mensajeThankYouSingular": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have ", _interpolate(_named("plazaslibres")), " free place, share the link to complete them or a supplement of ", _interpolate(_named("suplemento")), " will be added to each confirmed person."])},
    "mensajeThankYouPlural": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have ", _interpolate(_named("plazaslibres")), " free places, share the link to complete them or a supplement of ", _interpolate(_named("suplemento")), " will be added to each confirmed person."])},
    "mensajeThankYouCompleto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your booking consists of the following persons:"])},
    "mensajeThankYouAnular": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["If it is not completed by ", _interpolate(_named("fechaLimite")), " it will be automatically cancelled."])},
    "mensajeFechaLimite": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Payment deadline: ", _interpolate(_named("fechaLimite")), "."])},
    "repartirCoste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If the number of places is not filled, a supplement will be added for each confirmed person."])},
    "mensajeExperiencia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We can't wait to see you and enjoy the experience together."])},
    "msjAnadirExtras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can add some extras if you've forgotten"])},
    "anadirExtras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add extras"])},
    "masExtras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More extras"])},
    "extras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extras"])},
    "serviciosContrados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contracted services"])},
    "realizarPago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make payment"])},
    "cambioHabitacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room change"])},
    "informesTotales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total reports"])},
    "cancelacionEntre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["if the cancellation occurs between the"])},
    "costesAsociadosReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The costs associated with canceling the reservation are:"])},
    "msjReservaDescartada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The reservation was discarded"])},
    "msjReservaActiva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You already have a reservation for this trip. Choose another trip or check your reservations."])},
    "descuento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount"])},
    "descuentoCod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount code"])},
    "descuentoCodIntrod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter it here"])},
    "descuentoCodError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The discount code '", _interpolate(_named("codigo")), "'. It's not valid."])},
    "paqueteBasico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic package"])},
    "suplementosGastos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supplements and expenses"])},
    "msjPendientePagoReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have a pending reservation payment for this trip. Do you want to discard it or continue with it?"])},
    "revisaDatos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review your reservation details"])},
    "pagarTotalReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay the total amount of the reservation"])},
    "enlaceUnirse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At the end of the reservation, send the link that we will provide to the rest of the people so they can join"])},
    "recuerdaPagar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remember, you can pay the full reservation or just a part of it"])},
    "pagarAhora": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay now"])},
    "abonarAntesDe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and pay the remaining amount before"])},
    "pagarPlazos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay in installments"])},
    "reservaCancelada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation canceled"])},
    "msjCheckInReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To expedite the check-in process upon your arrival, it's necessary to fill out your information. You can fill out your information or that of everyone in your room/apartment."])},
    "msjDatosReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can check the reservation details for the people you made the first payment for"])},
    "disponeCodReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have a reservation code"])},
    "msjFiesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Someone mentioned a party"])},
    "msjNoFiesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no parties available for any of your trips yet. As soon as they are confirmed, you will be notified by email so you can book your spot."])},
    "msjAmigoReservado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you have a friend who has already booked and you want to join their room, click the button below"])},
    "gastos": {
      "gasto": {
        "tasa-turistica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tourist tax expenses"])},
        "gestion": {
          "extras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra management expenses"])}
        }
      },
      "FEE_PLATAFORMA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platform management fees"])},
      "incremento": {
        "camas": {
          "vacias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expense for remaining accommodation"])}
        }
      },
      "recargo": {
        "pago": {
          "tardio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Late payment fee"])}
        }
      },
      "complemento": {
        "plazas": {
          "libres": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supplement for free spots"])}
        }
      }
    },
    "visualizacionOcupantes": {
      "persona": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person"])},
      "plaza": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seat"])},
      "libre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free"])}
    }
  },
  "rooming": {
    "miRooming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Rooming"])},
    "resena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave us a review"])},
    "siguenos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow us"])},
    "gestorRooming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rooming Manager"])},
    "tooltipAnadirIntegrantes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Once you have clicked here, select the members from the table on the right"])},
    "organizarDistribucion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organize Distribution"])},
    "tooltipMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the room you want to distribute and then the people you want to put in that room from the table on the left"])},
    "cardHabitacion": {
      "anadirIntegrantes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+ Add members"])},
      "selectFromTable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select members from the table"])}
    },
    "estado": {
      "NUEVO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])},
      "EN_EDICION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In editing"])},
      "PUBLICADO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Published"])}
    },
    "tabla": {
      "nombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "apellidos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surname"])},
      "alojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accommodation"])},
      "sinRepartir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not distributed"])},
      "repartidos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distributed"])},
      "buscarNombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search name"])},
      "guardar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
      "descartar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "aceptar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept"])}
    },
    "enviar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "hasTerminado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have you finished?"])},
    "guardarSalir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save and exit"])},
    "guardarSalirMensaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you haven't finished distributing and want to continue later, click 'Save and exit'. If you are finished, click 'Send', note that once sent, no changes can be made."])},
    "error": {
      "publicar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error publishing your rooming."])},
      "guardar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error saving your trip"])}
    }
  },
  "validaciones": {
    "pagador": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payer: "])},
    "validadaError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ERROR: TICKET ALREADY VALIDATED"])},
    "validadaExito": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TICKET SUCCESSFULLY VALIDATED"])},
    "validadaFecha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validated on "])},
    "aLas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" at "])},
    "cantidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
    "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "unidades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["units"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "preguntaValidar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to validate the ticket?"])},
    "validar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validate"])}
  },
  "general": {
    "y": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and"])},
    "a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a"])},
    "el": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The"])},
    "aqui": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["here"])},
    "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of"])},
    "teneis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have"])},
    "viaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trip"])},
    "grupo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])},
    "crear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
    "antesDel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["before"])},
    "ahora": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["now"])},
    "checkInOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check In Online"])},
    "checkInRealizado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check In Completed"])},
    "codigoCopiado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code has been copied"])},
    "pagoPendiente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending Payment"])},
    "articulosRelacionados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Related Articles"])},
    "mejoresOfertas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Best Offers"])},
    "cancelacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellation"])},
    "verMas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show more"])},
    "verMenos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show less"])},
    "pagos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payments"])},
    "atras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "miPerfil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Profile"])},
    "siguiente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "ordenar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort by"])},
    "anios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["years"])},
    "buscar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "opcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Option"])},
    "experiencia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experience"])},
    "mostrarMas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show more"])},
    "viajeAMedida": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customized Experiences"])},
    "mostrarMenos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show less"])},
    "cerrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "cerrarSesion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log out"])},
    "solucion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solution"])},
    "para": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for"])},
    "pagado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid"])},
    "desde": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["from"])},
    "seleccion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selection"])},
    "terminosCondiciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms and Conditions"])},
    "proteccionDatos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal Data Protection"])},
    "porPersona": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per person"])},
    "masInformacionViaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More information about this experience"])},
    "masInformacionAlojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More accommodation information"])},
    "variasOcupaciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Various occupations available"])},
    "eligeAlojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose your accommodation"])},
    "quieroSaberMas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I want to know more"])},
    "verTodos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View all"])},
    "reservarAlojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book accommodation"])},
    "unirseHabitacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join room"])},
    "servicios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
    "ubicacionesAlojamientos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View map"])},
    "verUbicaciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See locations"])},
    "bookingLetterButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "codigoInvalido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The code is not valid."])},
    "bookingBusDeparture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Departure"])},
    "experienceFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trips for"])},
    "agotado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sold out"])},
    "dias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Days"])},
    "dia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day"])},
    "noches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nights"])},
    "noche": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Night"])},
    "del": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["from the"])},
    "al": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to the "])},
    "aplicar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])},
    "resumen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summary"])},
    "plazaUno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["spot"])},
    "plaza": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["spots"])},
    "cantidadDisponibles": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["More than ", _interpolate(_named("cantidad")), " available"])},
    "disponibles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["available"])},
    "agotadas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sold out"])},
    "reservar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book"])},
    "listaEspera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting list"])},
    "listaEsperaSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your request has been successfully added to the waiting list"])},
    "listaEsperaError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It was not possible to add to the waiting list"])},
    "personas": {
      "singular": {
        "adulto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["adult"])},
        "nino": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["child"])},
        "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["person"])}
      },
      "plural": {
        "adulto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["adults"])},
        "nino": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["children"])},
        "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["people"])}
      },
      "mayus": {
        "adulto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adult"])},
        "nino": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Child"])},
        "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person"])}
      }
    },
    "alojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accommodation"])},
    "gastos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expenses"])},
    "habitacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room"])},
    "habitaciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rooms"])},
    "regimen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regime"])},
    "clipboard": {
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL copied to clipboard"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error copying URL"])}
    },
    "esMiReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The reservation is for me"])},
    "nombreCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We will use the first and last name from your profile"])},
    "persona": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person"])},
    "menorEdad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am a minor"])},
    "si": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "masInformacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More information"])},
    "noInformacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No information available"])},
    "masInformacionIcon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+ information"])},
    "borrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "esMenorEdad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is a minor"])},
    "fecha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "invalidCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid Code"])},
    "politicaPrivacidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms and conditions"])},
    "errorMensaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error has occurred"])},
    "pendiente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
    "ejecutando": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Running"])}
  },
  "filtroAlojamiento": {
    "borrarFiltros": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear filters"])},
    "titulo": {
      "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter accommodation"])},
      "poblacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locations"])},
      "alojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of accommodation"])},
      "plaza": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of spaces"])},
      "reestablecer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset filters"])}
    }
  },
  "filtroModalidades": {
    "titulo": {
      "seleccionaGama": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select level"])},
      "seleccionaServicio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select service"])}
    }
  },
  "selectSubTipos": {
    "titulo": {
      "seguroAnulacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellation Insurance"])},
      "seguroViaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travel Insurance"])}
    }
  },
  "resumenServiciosBuscador": {
    "nochesAlojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["days of accommodation"])},
    "diasForfait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["days of ski pass"])}
  },
  "baseMigas": {
    "seleccionAlojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accommodation selection"])},
    "datosReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking"])},
    "confirmacionReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation confirmation"])},
    "pago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment"])},
    "seleccionFestival": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Festival selection"])}
  },
  "botones": {
    "reservarAlojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book accommodation"])},
    "recibo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receipt"])},
    "documentacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentation"])},
    "reservaPackFiesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book party pack"])},
    "unirseHabitacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join room"])},
    "volverViajes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to trips"])},
    "volverReservas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return to my bookings"])},
    "actualizar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
    "reservar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserve"])},
    "cerrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "verTodos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See all"])},
    "verDetalles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View details"])},
    "verReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Reservation"])},
    "bonoAdjunto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Attached Voucher"])},
    "cancelada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canceled"])},
    "descargar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
    "guardar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "volver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "entrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter"])},
    "enviar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "recuperar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recover"])},
    "copiar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy"])},
    "cancelar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "modificar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modify"])},
    "agregarPersona": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Person"])},
    "aceptar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept"])},
    "buscar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "darseBaja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsubscribe"])},
    "aceptarSeleccion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept Selection"])},
    "cancelarReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel Reservation"])},
    "descargarPdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download PDF"])},
    "modificarReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modify Reservation"])},
    "descartar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discard"])},
    "unirse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join"])},
    "reactivarReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reactivate Reservation"])},
    "recordar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remind"])},
    "pagarSenial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay Deposit"])},
    "pagar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay"])},
    "establecer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set"])},
    "resumen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summary"])},
    "verBono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voucher"])},
    "continuar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
    "reintentar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retry"])}
  },
  "codigoDescuento": {
    "tienesCodDescuento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you have a discount code?"])}
  },
  "cookies": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COOKIE POLICY"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We use cookies to improve our services, analyze and personalize your browsing. To continue browsing our website, you must accept their use. You can change the settings or get more information in our"])},
    "aceptar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept all cookies"])},
    "politicas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookies Policy"])},
    "gestionar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage cookies"])},
    "extras": {
      "necesarias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Necessary"])},
      "analiticas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analytical"])},
      "marketing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketing"])}
    },
    "preguntas": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WHAT ARE COOKIES?"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AUTHORIZATION FOR THE USE OF COOKIES?"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TYPES OF COOKIES USED?"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOW TO DISABLE OR DELETE COOKIES?"])}
    },
    "respuestas": {
      "1": {
        "bloque1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookies are text files that are stored on the user's device while browsing the Internet and, in particular, contain a number that allows the device to be uniquely identified, even if it changes location or IP address."])},
        "bloque2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookies are installed during Internet browsing, either by the websites visited by the user or by third parties with which the website is related, and they allow the website to know the user's activity on the same site or on others with which it is related. For example: the location from which it accesses, connection time, the device from which it accesses (fixed or mobile), the operating system and browser used, the most visited pages, the number of clicks made, and data regarding the user's behavior on the Internet."])},
        "bloque3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookies are associated only with your browser and do not provide personal data by themselves. Cookies cannot damage your device and are also very useful as they help us identify and resolve errors. The website is accessible without the need for cookies to be activated, although their deactivation may prevent its proper functioning."])}
      },
      "2": {
        "bloque1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In accordance with the cookie notice that appears in the website's footer, the user or visitor to the website expressly consents to the use of cookies as described below, except to the extent that they have modified their browser settings to reject the use of cookies."])}
      },
      "3": {
        "bloque1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The website owner uses strictly necessary cookies that serve to facilitate proper navigation on the website and to ensure that its content is loaded efficiently. These cookies are also session cookies, meaning they are temporary and expire and are automatically deleted when the user closes their browser."])},
        "bloque2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookies from Facebook Ireland Ltd., which are used to allow visitors to interact with Facebook content and are generated only for users of these social networks. The terms of use of these cookies and the information collected are regulated by the privacy policy of the corresponding social platform."])}
      },
      "4": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookies can be disabled and deleted following the following guides depending on the browser used:"])},
        "bloque1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete and manage cookies in Internet Explorer"])},
        "bloque2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete, enable, and manage cookies in Chrome"])},
        "bloque3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete cookies in Firefox"])},
        "bloque4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Safari web settings on iPhone, iPad, and iPod touch"])}
      }
    }
  },
  "politicasCancelacion": {
    "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellation Policies"])}
  },
  "titulos": {
    "incluye": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Included"])},
    "itinerario": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Itinerary"])},
    "extras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extras"])},
    "seleccionServicios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book for as many people as you want"])},
    "completarReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete the following data to continue with your reservation"])},
    "motivosCancelacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellation Reasons"])},
    "editarPerfil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Profile"])}
  },
  "subtitulo": {
    "seleccionServicios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From Ski&Night, we give you the option for each person to choose their extras and pay their part. Let us know if you want to reserve the entire trip yourself. If you only reserve yours, we will send you a code for your friends to reserve their part."])},
    "nombreHabitacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the name of your room"])},
    "comoConociste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How did you get to know us?"])},
    "escribeRespuesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write your answer"])},
    "estudiando": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you studying?"])},
    "eligeUniversidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a university"])},
    "comercial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salesperson's Name"])},
    "trabajando": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Which company are you working for?"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "importeTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Amount"])},
    "totalReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total reservation"])},
    "codigoReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking Code"])},
    "codigoViaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travel Code"])},
    "intoduceAqui": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter it here"])},
    "codigo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
    "misViajeros": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Travelers"])},
    "miPerfil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Profile"])},
    "mailingPublicidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advertising Mailing"])},
    "subirDni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload passport"])},
    "modificarcontrasenia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Password"])},
    "datos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])}
  },
  "modalUnirseHabitacion": {
    "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Did you run out of accommodation?"])},
    "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in the following data, and in case a room becomes available, you will be notified."])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you want to join a room on a trip, use the code provided by the person who made the reservation."])}
  },
  "input": {
    "field": {
      "nombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "apellido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
      "nombreApellidos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name and Last Name"])},
      "correo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "telefono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
      "numeroPlazas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Seats"])},
      "observaciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Observations"])},
      "redesSociales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social Media"])},
      "comerciales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commercial"])},
      "paqueteBasico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic Package"])}
    },
    "placeholder": {
      "nombreHabitacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your room name"])},
      "introduceCampo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in"])}
    }
  },
  "login": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buyer's Information"])},
    "subtitleRegister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not a customer yet? Register"])},
    "forgetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot your password"])},
    "recoverPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recover Password"])},
    "questionLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already a customer? Log in"])},
    "questionUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already a user"])},
    "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password"])},
    "establecerContrasenia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set User Password"])},
    "entrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter"])}
  },
  "experiencia": {
    "IntroduceCodViaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the code of your experience"])},
    "colegios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you are between 5 and 17 years old and want to make the most of your days skiing, this is your experience. Improve your technique and enjoy nature, we provide transportation, accommodation, and instructors."])},
    "familias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We offer you the most comfortable accommodation to travel to the snow with your family. Additionally, you can hire ski lessons for children and many other activities tailored to your needs."])},
    "trabajadores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traveling with your work colleagues is very easy. If you are over 25 years old, join our experiences organized to meet people like you, where skiing and nightlife will surprise you."])},
    "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is your experience if you want to meet people of your age at the best festivals and après-skis. Do you dare to live an unforgettable experience in the snow?"])}
  },
  "viaje": {
    "IntroduceCodViaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your travel code"])},
    "colegios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you are between 5 and 17 years old and want to make the most of your skiing days, this is your trip. Improve your technique and enjoy nature. We provide transportation, accommodation, and monitors."])},
    "familias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We offer the most comfortable accommodation for traveling to the snow with your family. You can also book ski lessons for children and many other activities tailored to your needs."])},
    "trabajadores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traveling with your coworkers is very easy. If you are over 25, join our organized trips to meet people like you, where skiing and nightlife will amaze you."])},
    "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is your trip if you want to meet people of your age at the best festivals and après-ski parties. Are you ready for an unforgettable experience in the snow?"])}
  },
  "errors": {
    "habitacion": {
      "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The code does not correspond to any room"])}
    },
    "grupoReservas": {
      "pendientes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are reservations pending to be confirmed"])}
    },
    "experiencia": {
      "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The experience does not allow new reservations"])}
    },
    "reserva": {
      "alreadyExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One of the travellers already has a reservation for this experience"])}
    },
    "servicios": {
      "cuposInsuficientes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some of the selected services are sold out"])}
    },
    "pago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error during the payment"])},
    "confirmacionPago": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your payment is being verified."])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There has been a communication issue with the payment gateway. This incident should be resolved automatically within a few hours. If your booking does not appear in your profile within 24 hours, please contact us."])}
    },
    "pagoTransferencia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment for this reservation is by bank transfer"])},
    "cargaInformacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an issue loading the information. Please try again later."])},
    "controlErrorValidarCupo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The requested trip does not exist."])},
    "noAdmiteReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New reservations are not allowed for this trip."])},
    "precioCambiado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A price change has been detected"])},
    "inesperado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unexpected error. Please try again later."])},
    "loginReservaDescartar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need to log in to discard the reservation."])},
    "permisosDescartarReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have permission to discard the reservation."])},
    "descartarReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The reservation cannot be discarded."])},
    "intentalo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unexpected error. Please try again later."])},
    "obligatorios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are mandatory fields that are not filled out."])},
    "loginReservaCrear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need to log in to create the reservation."])},
    "permisosCrearReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have permission to create the reservation."])},
    "noInfoReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation information could not be found."])},
    "existeReservaViaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A reservation for this trip already exists."])},
    "emailRegistrado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The email is already registered."])},
    "permisosProcesos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have permission to perform this process."])},
    "camposSinCompletar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are incomplete fields."])},
    "recursoNoLocalizado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The requested resource could not be located."])},
    "conflictoEstadoReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is a conflict in the reservation status."])},
    "errorContrasenia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error in entering the password."])},
    "loginRequerido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need to log in to continue."])},
    "reservaNoPendiente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The reservation is not in a pending state."])},
    "noAlojamientos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No accommodations found."])},
    "intenteReservaTarde": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please try to make a reservation later. If the error persists, please contact us."])},
    "generacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error in generation"])},
    "descarga": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error in download"])}
  },
  "servicios": {
    "repercutirFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Management fees"])},
    "tasaDe": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("nombreTasa")), " Fees"])},
    "forfait": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ski Pass"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can change the days of your ski pass. Select the days you want."])}
    },
    "comida": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Food"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose if you want food on the slopes."])}
    },
    "transporte": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transportation"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose your means of transportation."])}
    },
    "seguro": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insurance"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose your insurance."])},
      "verPoliza": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View policy"])},
      "subtipo": {
        "cancelacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellation"])},
        "salud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Health"])}
      }
    },
    "actividad": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activities"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the activity."])}
    },
    "festival": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Festivals"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the ticket you want and come to enjoy the full experience."])}
    },
    "clase": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lessons"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can choose the days of classes. Select the days you want."])}
    },
    "alquiler": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rentals"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose if you want to rent equipment."])}
    },
    "greenFee": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Green Fee"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose if you want a Green Fee."])}
    },
    "relax": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relax"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose if you want a relax service."])}
    },
    "personal": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the personal."])}
    },
    "parking": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parking"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select your preferred parking."])}
    },
    "mascota": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pet"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose pet services."])}
    },
    "competicion": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competition"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the competition"])}
    },
    "tooltipMessageIsNominal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some services are nominal, which means that only one service can be selected per person."])},
    "servicioNominal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nominal service"])},
    "noDisponibilidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No more availability for this service"])},
    "maximoPermitido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have reached the maximum number of services per person"])}
  },
  "trabajaNosotros": {
    "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work with us"])}
  },
  "cardReservaPlazasLibres": {
    "descripcion": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You still have ", _interpolate(_named("plazasLibres")), " available spots in your accommodation, which amounts to a pending payment of ", _interpolate(_named("pagoPendiente")), ". You have two options:"])},
    "opcionUno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share the link to complete the available spots."])},
    "opcionDos": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pay the outstanding amount of ", _interpolate(_named("pagoPendiente")), " among the confirmed individuals."])},
    "cancelacion": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["If neither of the two options is carried out before ", _interpolate(_named("fecha")), ", the ", _interpolate(_named("final"))])},
    "cancelacionBold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reservation will be automatically canceled."])},
    "msjPlazaLibresPagadas": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have ", _interpolate(_named("plazasLibres")), " free places, share the link to complete them and the supplement “Free places” will be recalculated in your favor."])},
    "button": {
      "abonarPago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay pending amount"])},
      "copiarLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy Link"])}
    }
  },
  "langs": {
    "es": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spanish"])},
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French"])},
    "it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italian"])},
    "ca": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catalan"])}
  }
}