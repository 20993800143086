export default {
  "actions": {
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar"])}
  },
  "header": {
    "horarioAtencion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["D-J de 9:00h-18:30h, V de 9:00-14:00h"])},
    "ski": {
      "groups": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les nostres experiències"])},
        "university": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Universitaris"])},
        "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escoles"])},
        "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treballadors"])},
        "premium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premium"])},
        "family": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Famílies"])}
      },
      "destinations": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinacions"])}
      },
      "stations": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estacions"])}
      }
    },
    "night": {
      "festivals": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Festivals"])}
      },
      "apres": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Après ski"])}
      }
    },
    "us": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nosaltres"])},
      "whoWeAre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qui som?"])},
      "work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treballa amb nosaltres"])}
    },
    "user": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfil"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sessió"])},
      "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrar-se"])}
    },
    "login": {
      "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benvingut!"])},
      "welcomePlanner": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Benvingut a ", _interpolate(_named("planner")), "!"])},
      "continuarGoogle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar amb Google"])},
      "tusDatos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les teves dades"])},
      "restablecerPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restablir contrasenya"])}
    }
  },
  "searcher": {
    "organized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les nostres experiències"])},
    "personalized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experiències a mida"])},
    "destiny": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinació"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On voleu anar?"])}
    },
    "date": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dates"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tria quan podeu anar"])}
    },
    "datesAccomodation": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dates allotjament"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tria quan voleu anar"])}
    },
    "datesForfait": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dates forfait"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dates de forfait"])}
    },
    "rooms": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Habitacions"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tria les habitacions"])}
    },
    "groups": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipus de grup"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["D'on veniu?"])}
    },
    "resumenReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resum Reserva"])}
  },
  "filters": {
    "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtre"])},
    "plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtres"])},
    "restablecer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restablir"])},
    "alojamiento": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["T'has quedat sense allotjament"])},
      "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omple les següents dades i en cas de quedar lliure alguna habitació se t'avisarà"])}
    }
  },
  "home": {
    "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inici"])},
    "banner": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VIU L'EXPERIÈNCIA COMPLETA"])},
      "titleBsspain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ELS TEUS ESDEVENIMENTS DE SALSA I BACHATA"])},
      "titlePr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RESERVA PREFLOP ELS TEUS VIATGES DE POKER"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uneix-te a les nostres experiències amb els teus amics i coneix una comunitat de viatgers com tu. Selecciona una destinació, les dates en què t'agradaria viatjar i el teu origen. A què esperes per unir-te a les millors experiències a la neu?"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recomanador d'experiència"])},
      "eligeFecha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tria quan viatjar"])},
      "experienciasAnyo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experiències 24/25"])},
      "experiencias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experiències"])},
      "experienciasAMedida": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experiències a mida"])},
      "eligeTuEvento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tria el teu esdeveniment"])}
    },
    "festivales": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LES NOSTRES EXPERIÈNCIES"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viatja amb els teus amics a les nostres destinacions favorites on podràs gaudir dels nostres dos esdeveniments principals: AWS Formi i WSF Pas de la Casa."])},
      "titleDefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les nostres experiències"])},
      "subtitleDefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descobreix experiències úniques dissenyades per connectar comunitats a través de viatges i esdeveniments"])},
      "titleBsspain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tria el teu esdeveniment"])},
      "subtitleBsspain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salsa, bachata o mixt en qualsevol data o ciutat"])},
      "titlePr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propers tornejos"])},
      "subtitlePr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viatja amb els teus millors amics als millors festivals de poker del país i allotja't sempre a prop del casino"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dates"])},
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Localització"])}
    },
    "buscadorSection": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fes la teva experiència a mida"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No t'encaixen els nostres viatges de grup? Utilitza el nostre cercador per configurar el viatge totalment a mida."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anar al cercador"])}
    },
    "porqueReservar": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per què reservar amb Ski&Night?"])},
      "skiEnjoy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SKI, ENJOY"])},
      "sleep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLEEP, "])},
      "repeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REPEAT"])},
      "texto1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organitza el teu viatge a la neu a qualsevol destinació d'Europa."])},
      "texto2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" són les destinacions perfectes per gaudir de la neu i del millor oci nocturn."])},
      "texto3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Ski&Night t'oferim una experiència única en les millors destinacions en ple paradís natural. Et facilitem l'"])},
      "texto4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" i l'accés a festivals perquè només et preocupis de gaudir."])},
      "alojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["allotjament, les classes"])},
      "material": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["el lloguer de material"])}
    },
    "packs": {
      "school": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experiències per a escoles"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si tens entre 5 i 17 anys i vols aprofitar al màxim els dies d'esquí, aquesta és la teva experiència. Millora la teva tècnica i gaudeix de la natura, nosaltres et facilitem el transport, allotjament i monitors."])}
      },
      "family": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experiències per a famílies"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["T'oferim l'allotjament més còmode per viatjar a la neu amb la teva família. A més, podràs contractar classes d'esquí per a nens i moltes altres activitats adaptades a les teves necessitats."])}
      },
      "university": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experiències per a universitaris"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aquesta és la teva experiència si vols coincidir amb gent de la teva edat en els millors festivals i après-skis. T'atreveixes a viure una experiència inoblidable a la neu?"])}
      },
      "company": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experiències per a treballadors"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viatjar amb els teus companys de feina és molt fàcil. Si tens més de 25 anys, uneix-te a les nostres experiències organitzades per coincidir amb gent com tu, en les quals l'esquí i l'oci nocturn et sorprendran."])}
      },
      "premium": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experiències Premium"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserva una experiència exclusiva als millors allotjaments a la neu complementant els dies d'esquí amb experiències úniques a la neu: gourmet, relax, esports extrems o experiències culturals."])}
      }
    },
    "festival": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Winter Snow Festival, el nostre festival"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Ski&Night hem dissenyat l'experiència Winter Snow Festival, el nostre festival a la neu en el qual podràs gaudir dels DJs i músics més exclusius del moment en plena muntanya."])},
      "description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aquest any celebrem la 4a edició del WSF, una experiència única a la neu que podràs gaudir a Andorra juntament amb més de 5.000 persones com tu."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consulta els nostres festivals"])}
    },
    "apres": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Els millors après skis"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Som especialistes en organitzar les millors festes a la neu. Després de passar el dia a la neu podràs gaudir de bona música de la mà dels nostres DJs en un entorn incomparable. Et presentem una selecció amb els après ski més exclusius:"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veure tots"])}
    },
    "experiencias": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les millors experiències"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veure tots"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totes"])}
    },
    "offers": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les millors ofertes"])}
    },
    "stations": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les millors estacions d'esquí"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organitzem les millors escapades d'experiències a la neu a Formigal, Grandvalira i Sierra Nevada, entre altres estacions. Fes una ullada a les estacions en les quals organitzem experiències i tria la que millor s'adapti a tu."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar Tots"])}
    },
    "form": {
      "realizarCheckin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Realitzar checkin"])},
      "others": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altres"])},
      "backButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tornar"])},
      "tamañoMaximo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'arxiu màxim per pujar és de "])},
      "nextButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])},
      "personalInformation": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dades Personals"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
        "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cognoms"])},
        "expedicionDni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data d'expedició del DNI/Passaport"])},
        "pais": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["País"])},
        "passwordRegister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrasenya"])},
        "ocultar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amagar"])},
        "alergias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al·lèrgies"])},
        "mostrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar"])},
        "password8CharReq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Almenys 8 caràcters"])},
        "password1MayusReq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Almenys 1 majúscula"])},
        "password1NumReq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Almenys 1 número"])},
        "passwordSeguridadBuena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguretat de la contrasenya: bona"])},
        "welcomeBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benvingut de nou"])},
        "emailLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introdueix el teu email per iniciar sessió o registrar-te"])},
        "dataRegister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introdueix les teves dades per registrar-te"])},
        "contraseniaLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introdueix la teva contrasenya per iniciar sessió"])},
        "emailOtherLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicia sessió amb un altre compte"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correu electrònic"])},
        "confirmEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar correu electrònic"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrasenya actual"])},
        "newpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nova contrasenya"])},
        "confirmnewpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar nova contrasenya"])},
        "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telèfon"])},
        "direccion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adreça"])},
        "poblacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Població"])},
        "provincia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Província"])},
        "codPostal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codi Postal"])},
        "birthDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de Naixement"])},
        "sexo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sexe"])},
        "hombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
        "mujer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dona"])},
        "nacionalidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nacionalitat"])},
        "diaHoraLlegada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dia i hora d'arribada"])},
        "horaSalida": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dia i hora de sortida"])},
        "lugarNacimiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lloc de Naixement"])},
        "diaLlegada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dia d'Arribada"])},
        "horaLlegada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hora d'Arribada"])},
        "anversoReversoDni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anvers i Revers DNI pujat"])},
        "alquilerMaterial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lloguer de material"])},
        "descargarPdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descarregar PDF"])},
        "descripcionAlquilerMaterial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les dades són orientatives, a la pròpia botiga podràs provar-te i canviar-lo pels que millor s'ajustin"])},
        "altura": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alçada (cm)"])},
        "ingresaAltura": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introdueix l'alçada"])},
        "peso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pes (kg)"])},
        "ingresaPeso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introdueix el pes"])},
        "tallaPie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talla de peu (EU)"])},
        "ingresaTallaPie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introdueix la talla de peu"])},
        "emailComercialesActivos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email comercials actius"])},
        "title-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vols explicar-nos alguna cosa més sobre tu?"])},
        "write": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escriu aquí"])},
        "ADni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anvers del DNI/Passaport"])},
        "msjADni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haurà de ser un pdf amb format png, jpg o gif"])},
        "selectedFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar document"])},
        "selecciona": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona"])},
        "RDni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revers del DNI/Passaport"])},
        "anversoReversoDnis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anvers i revers del DNI/Passaport"])},
        "howDid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Com ens vas conèixer?"])},
        "cv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vols, pots adjuntar-nos el teu CV"])},
        "errorPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error en validar la contrasenya, comprova les credencials."])},
        "noTienesCuenta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No tens compte?"])},
        "yaTienesCuenta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja tens compte?"])},
        "aceptoMailing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepto l'enviament de novetats i comunicacions comercials."])},
        "noRecibirMailing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No vull rebre novetats i comunicacions comercials."])},
        "registrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrar-se"])},
        "errorRegPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La contrasenya ha de complir amb tots els requisits de seguretat."])},
        "logeate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicia sessió"])},
        "dni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document d'identitat/Passaport"])},
        "msjcontrasenia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perquè la teva contrasenya sigui més segura, et recomanem que utilitzis una combinació de lletres majúscules i minúscules, números i caràcters especials com *, %, !."])},
        "continuar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])},
        "iniciarSesionRegistrarReservar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicia sessió o registra't per reservar"])},
        "registradoCorrect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrat correctament"])}
      },
      "tripInformation": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Característiques del Viatge"])},
        "whereFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["D'on viatges?"])},
        "howMany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantes persones sou?"])},
        "whoTravel": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amb qui viatges?"])},
          "friends": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amics"])},
          "family": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Família"])},
          "couple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parella"])},
          "work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Companys de feina"])}
        },
        "places": {
          "andorra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andorra"])},
          "formigal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formigal"])},
          "sierraNevada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sierra Nevada"])},
          "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altres llocs"])},
          "noPreference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sense preferència"])}
        },
        "fields": {
          "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentaris"])},
          "otherStation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altra estació"])},
          "others": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altres"])},
          "writeHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escriu aquí"])},
          "otherInterest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altres interessos"])},
          "otherCompanion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altres acompanyants"])}
        },
        "interests": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Què t'interessa més?"])},
          "cousine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gastronomia"])},
          "party": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Festa i ambient"])},
          "relax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relax i spa"])},
          "snow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activitats a la neu"])},
          "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altres interessos"])}
        },
        "distance": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distància a les pistes"])},
          "near": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A prop de les pistes"])},
          "noPreference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sense preferència"])}
        },
        "whenTravel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dates del viatge"])},
        "howManyDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quants dies vols esquiar?"])},
        "destiny": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On vols anar?"])},
          "noPreference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sense preferència, recomana'm!"])}
        },
        "accommodation": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On prefereixes allotjar-te?"])},
          "hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel"])},
          "apartment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apartament"])},
          "noPreference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No tinc preferència"])}
        },
        "tracks": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distància a les pistes?"])},
          "near": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vull estar al costat de les pistes"])},
          "noPreference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No m'importa moure'm"])}
        }
      },
      "Thanks": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gràcies"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Et tornarem a contactar en menys de 48 hores!"])},
        "title-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gràcies per la informació. Aviat tindràs notícies"])}
      },
      "policy": {
        "readAccept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["He llegit i accepto la"])},
        "readAccepts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["He llegit i accepto les"])},
        "readAcceptsLos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["He llegit i accepto els"])},
        "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i els"])},
        "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["termes i condicions"])},
        "freePlacesPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de places lliures"])},
        "policyCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de Cancel·lació"])},
        "policyAlojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política d'Allotjament Plena Ocupació"])},
        "cancelReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel·lació de la Reserva"])},
        "titleCondicionesCancelacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condicions i despeses de cancel·lació"])},
        "msgCondiciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les condicions de reserva per a l'experiència són les següents."])},
        "descriptionCondiciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si el client desisteix de l'experiència, després de la formalització del dipòsit exigit per considerar ferma la reserva REEMBORSABLE, hauria d'abonar les següents sumes:"])},
        "descriptionCondicionesNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si el client desisteix de l'experiència, després de la formalització del dipòsit exigit per considerar ferma la reserva NO REEMBORSABLE, hauria d'abonar les següents sumes:"])},
        "detail1Condiciones": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El ", _interpolate(_named("porcentaje")), "% si es produeix entre ", _interpolate(_named("fechaInicio")), " i abans de ", _interpolate(_named("fechaFin"))])},
        "detail2Condiciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El 100% si és dins dels 15 dies naturals abans de la sortida."])},
        "detail3Condiciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La reserva de l'experiència no es retornarà sota cap circumstància"])},
        "detail4Condiciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["També pots realitzar un canvi de titular de la reserva perquè un company et substitueixi, aquest canvi es podrà realitzar amb una antelació màxima de 5 dies abans de la data d'inici de l'experiència i pot tenir un cost associat."])},
        "detail5Condiciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les despeses de gestió associades a modificacions en la reserva o per l'incompliment dels terminis del pagament de la mateixa no seran reemborsables en cap cas."])},
        "detail1Condiciones1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Del ", _interpolate(_named("fechaInicio")), " al ", _interpolate(_named("fechaFin"))])},
        "detail2Condiciones1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Els suplements i despeses associades a modificacions en la reserva o per l'incompliment dels terminis del pagament de la mateixa i les taxes no seran reemborsables en cap cas."])},
        "detail3Condiciones1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Podràs modificar la teva reserva de manera gratuïta fins al ", _interpolate(_named("fecha")), " a l'apartat Les meves reserves en el teu perfil."])},
        "detail4Condiciones1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si el client desisteix de l'experiència, després de la formalització del dipòsit exigit per considerar ferma la reserva NO REEMBORSABLE, hauria d'abonar les següents sumes:"])}
      }
    }
  },
  "sponsors": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sponsors i col·laboradors"])}
  },
  "footer": {
    "us": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qui som?"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacte"])},
      "work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treballa amb nosaltres"])},
      "blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog"])},
      "faqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQs"])}
    },
    "saleGroups": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ofertes per a grups"])},
      "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experiències per a escoles"])},
      "family": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experiències per a famílies"])},
      "university": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experiències per a universitaris"])},
      "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experiències per a empreses"])},
      "premium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experiències premium"])}
    },
    "saleSki": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ofertes per esquiar"])},
      "december": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esquí Pont de Desembre"])},
      "christmas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esquí Nadal"])},
      "newYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esquí Any Nou"])},
      "holyWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esquí Setmana Santa"])},
      "others": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altres ofertes"])}
    },
    "terminosCondiciones": {
      "bono": {
        "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condicions legals de venda de serveis TRAVEL&NIGHT S.L. - NIF B87814125 L'adquisició d'aquesta entrada representa l'acceptació de les condicions legals, política de privacitat i cookies que pot consultar a la web de la compra. Aquest bo és revocable en qualsevol moment per l'organitzador mitjançant l'oferta de la devolució de l'import del mateix. L'organització estableix les seves pròpies condicions legals per a l'accés i venda de serveis que hauràs de consultar en els seus mitjans o sol·licitar expressament al mateix. Per a qualsevol reclamació relativa a l'organització de l'esdeveniment o experiència, el portador d'aquest bo haurà de dirigir-se a l'organitzador. No s'admeten canvis o devolucions, només aquelles autoritzades per l'organitzador. L'organització es reserva el dret d'admissió i podrà negar o expulsar el gaudi de qualsevol dels serveis que formen part de l'experiència. TRAVEL&NIGHT no es responsabilitza de cap problema derivat de la falsificació, duplicació o venda il·legal d'aquesta entrada. En cas de devolucions, l'organitzador es reserva el dret a no retornar les despeses de gestió ja que aquesta ha estat correctament realitzada per la plataforma."])}
      }
    },
    "copyright": {
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juan Hurtado de Mendoza, 17 posterior, 1º Dcha, 28036 - Madrid, Espanya"])},
      "legal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avís legal"])},
      "cookies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de cookies"])},
      "faqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQs"])},
      "conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condicions generals"])}
    },
    "social": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segueix-nos!"])}
  },
  "us": {
    "whoWeAre": {
      "banner": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SOBRE NOSALTRES"])},
        "text": {
          "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Som una plataforma digital que està revolucionant el sector de l'esquí, dissenyant experiències a la neu, tant individuals com en grup. Des del nostre origen, hem portat a més de 30.000 viatgers a totes les estacions d'Espanya, Andorra i França."])},
          "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La nostra especialitat és connectar viatgers i crear comunitats a través de l'oci i l'esport a la neu. Comptem amb els millors après-skis i amb un festival propi a la neu, el Winter Snow Festival a Andorra. A més, oferim experiències a mida per a famílies, empreses, escoles i gourmet en els quals ens adaptem a les necessitats i gustos dels nostres viatgers, oferint-los la millor oferta de relax, restauració i oci."])}
        }
      },
      "team": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EQUIP SKI&NIGHT"])}
      },
      "values": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VALORS DE SKI&NIGHT"])},
        "enviroment": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ambient jove i proper"])},
          "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Ski&Night som una empresa jove, formada per un equip proper i flexible, el que ens permet conèixer molt bé el producte que venem."])},
          "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El bon rotllo es transmet a l'hora de crear experiències a la neu i en la forma d'adaptar-nos a les necessitats dels nostres viatgers, tant per a universitaris com per a escoles, empreses, gourmet i famílies."])}
        },
        "honesty": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Honestedat i transparència"])},
          "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'honestedat és una de les claus de la nostra companyia, sent la nostra màxima a l'hora d'actuar tant amb els nostres viatgers com de cara als nostres proveïdors."])},
          "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Des del nostre origen, més de 25.000 viatgers han confiat en nosaltres i un alt percentatge repeteix la seva experiència amb S&N, amb una taxa de fidelitat que ascendeix al 83,8 %."])}
        },
        "compromise": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compromís i sentit de pertinença"])},
          "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La marca Ski&Night s'ha convertit en un estil de lideratge i compromís, una comunitat que ha evolucionat des del seu origen donant lloc a una marca líder en el sector de la neu."])},
          "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un compromís que s'estén no només a la relació amb els viatgers, sinó als proveïdors, estacions i l'entorn natural en el qual es desenvolupa la nostra activitat."])}
        },
        "figures": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GRANS XIFRES GLOBALS"])},
          "experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANYS D'EXPERIÈNCIA"])},
          "travelers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VIATGERS"])},
          "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VIATGES GRUP"])},
          "collaborations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COL·LABORACIONS"])}
        },
        "season": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RESUM TEMPORADA 2021/22"])}
      }
    }
  },
  "porqueReservar": [
    {
      "icono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comoditat en el pagament"])},
      "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decideix si prefereixes pagar l'experiència completa o només la teva part i consulta l'estat al teu perfil."])}
    },
    {
      "icono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atenció personalitzada"])},
      "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El nostre personal d'atenció al client i guies a destinació resoldran tots els teus dubtes."])}
    },
    {
      "icono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flexibilitat en les teves experiències"])},
      "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona els extres i serveis que necessitis i personalitza la teva experiència al màxim."])}
    },
    {
      "icono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oci a la teva mida"])},
      "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gaudeix d'una àmplia oferta de relax, activitats a la neu, gastronomia i oci nocturn."])}
    }
  ],
  "porqueReservar2": [
    {
      "icono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comoditat en el pagament"])},
      "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decideix si prefereixes pagar l'experiència completa o només la teva part i consulta l'estat al teu perfil."])}
    },
    {
      "icono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atenció personalitzada"])},
      "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El nostre personal d'atenció al client i guies a destinació resoldran tots els teus dubtes."])}
    },
    {
      "icono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flexibilitat en les teves experiències"])},
      "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona els extres i serveis que necessitis i personalitza la teva experiència al màxim."])}
    },
    {
      "icono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oci a la teva mida"])},
      "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gaudeix d'una àmplia oferta de relax, activitats a la platja, gastronomia i oci nocturn."])}
    }
  ],
  "viajesCatalogo": {
    "availableTrips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["experiències disponibles"])},
    "placeholderSelectMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona un mes"])},
    "tituloFiltroPoblaciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poblacions"])},
    "tituloFiltroLugarExperiencia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lloc de l'experiència"])},
    "tituloFiltroPrecios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preus"])},
    "tituloFiltroAgrupaciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agrupacions"])},
    "tituloFiltroCategorias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
    "filtroPoblaciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tria els llocs als quals t'agradaria anar"])},
    "filtroPrecios": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tria el rang de preus (", _interpolate(_named("currency")), ") en els quals vols buscar"])},
    "filtrosFestivales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tria els festivals als quals t'agradaria anar"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uneix-te a una d'aquestes experiències en grup i gaudeix de l'experiència amb els teus amics. Pots reservar una nova habitació o unir-te a una existent"])},
    "descriptionPremium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserva una de les nostres experiències exclusives a la neu amb experiències de relax, gourmet, culturals o extremes. També pots contactar directament amb nosaltres en aquest telèfon <a href='https://wa.me/+34910054547' target='_blank'><span class='mx-2 text-primary'> +34 910054670 </span></a> i dissenyarem una experiència completament adaptada a les teves necessitats."])}
  },
  "seleccionAlojamientos": {
    "1availableTrips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allotjament disponible"])},
    "availableTrips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allotjaments disponibles"])},
    "descriptionTrips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per a la teva experiència en grup tenim disponibles aquests allotjaments"])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veure ubicacions"])},
    "places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["allotjaments"])},
    "1places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["allotjament"])},
    "ordenarPor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordenar per"])},
    "ordenarMenorMayor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordenar de menor a major preu"])},
    "ordenarMayorMenor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordenar de major a menor preu"])}
  },
  "mejoresOfertas": {
    "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les millors ofertes"])}
  },
  "porqueReservaTitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per què reservar a Ski and Night?"])},
  "porqueReservaTitulo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per què reservar a Sea and Night?"])},
  "habitacionBuscador": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajuda'ns a trobar el millor preu, organitza les teves habitacions"])},
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona el nombre d'habitacions/apartaments"])},
    "habitacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Habitació"])},
    "adulto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adults"])},
    "ninos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nens"])},
    "nino": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nen"])},
    "edadNino": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edat dels nens"])}
  },
  "viajesGrupos": {
    "todosPublicos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tots els públics"])},
    "mas25anios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Més de 25 anys"])},
    "de18a25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De 18 a 25 anys"])},
    "de0a17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De 0 a 17 anys"])},
    "msjPlazasLibres": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["places lliures a l'habitació, en cas de quedar-se lliures s'assumirà un sobrecost de"])},
    "msjRepartir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per cada plaça lliure a repartir entre els integrants de l'habitació en concepte de “Resta de l'allotjament”"])},
    "modificarReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "plazaLibre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plaça lliure"])},
    "invitarCorreo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pots convidar per correu prement el botó."])},
    "llenarInformacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omplir la informació de Explica'ns una mica més de tu."])},
    "faltaPoliticasPrivacidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falta confirmar polítiques de privacitat i política d'allotjament plena ocupació."])},
    "faltaPoliticasCancelacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falta confirmar polítiques de cancel·lació."])},
    "faltaRespuestasFormulario": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falta respondre totes les preguntes del formulari."])},
    "infoSeleccionServicios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En acabar la reserva, envia l'enllaç que et proporcionarem a la resta de persones perquè s'uneixin a la teva experiència."])}
  },
  "reserva": {
    "misReservas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les meves reserves"])},
    "reactivarParaRecuperla": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prem el botó reactivar per recuperar-la"])},
    "proximasReservas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les teves pròximes reserves"])},
    "misQRs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Els meus QRs"])},
    "proximasNights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Els meus pròxims tiquets"])},
    "estadoPagoReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estat del pagament de la reserva"])},
    "pendientePago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendent de pagament"])},
    "totalReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total reserva"])},
    "reservaEncuentra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aquesta reserva es troba"])},
    "miViaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La meva experiència"])},
    "verQRs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veure QRs"])},
    "reservasCancelar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona les reserves que vulguis cancel·lar"])},
    "mensajeCompraExp": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Prem ", _interpolate(_named("aqui")), " per veure totes les experiències disponibles"])},
    "mensajeCompraTicket": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Prem ", _interpolate(_named("aqui")), " per veure totes les festes disponibles"])},
    "graciasReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gràcies per reservar"])},
    "noReservas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encara no tens experiències"])},
    "noFiestas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encara no tens tiquets"])},
    "moreAbout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["explica'ns una mica més de tu"])},
    "howPay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tria com vols pagar"])},
    "pagoPlazosDeshabilitado": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ja estàs al dia amb el teu calendari de pagaments, pots realitzar el següent pagament a partir del ", _interpolate(_named("fecha")), "."])},
    "pagoSenial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagament senyal"])},
    "totalPagado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total pagat"])},
    "totalPendiente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total pendent"])},
    "saldoPendiente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo pendent"])},
    "pago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagament"])},
    "paga": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paga"])},
    "pagoTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagament total reserva"])},
    "totalDevolver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TOTAL a retornar"])},
    "gastoCancelacion": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Despeses de cancel·lació ", _interpolate(_named("fechaHoy"))])},
    "complementos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complements"])},
    "suplementos": {
      "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suplementos i despeses"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suplementos i despeses"])},
      "PLAZAS_LIBRES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Places lliures"])}
    },
    "tasas": {
      "externas": {
        "TASA_TURISTICA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxa turística"])}
      },
      "internas": {
        "TASA_GESTION_PLATAFORMA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxa de gestió de la plataforma"])},
        "TASA_GESTION_CAMBIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxa de gestió de canvi"])}
      }
    },
    "tasa": {
      "externas": {
        "turistica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxa turística"])}
      },
      "gestion-plataforma": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxa de gestió de la plataforma"])},
      "gestion-cambio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxa de gestió de canvi"])}
    },
    "pagos": {
      "pago": {
        "senial": {
          "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagament senyal"])}
        },
        "total": {
          "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagament de l'experiència total"])}
        },
        "viaje": {
          "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagament parcial"])},
          "diferido": {
            "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagament diferit"])}
          }
        }
      }
    },
    "tipo": {
      "PC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pensió completa"])},
      "MP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitja pensió"])},
      "AD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allotjament i esmorzar"])},
      "SA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Només allotjament"])},
      "SD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Només esmorzar"])}
    },
    "superGrupo": {
      "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Super-Grup"])},
      "informacionTransporte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informació Transport"])},
      "informacionViaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informació de la teva experiència"])},
      "fiestaContradas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Festes contractades"])},
      "crear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear super-grup"])},
      "unirse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unir-se a super-grup"])},
      "msjNoGrupoamigo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El teu grup encara no té cap grup amic. Crea un supergrup o uneix-te a un existent abans de la data de comanda per viatjar en el mateix autobús i allotjar-te en habitacions el més properes possibles."])},
      "msjSuperGruposEdificio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Els supergrups només es tindran en compte per a edificis propers a causa del punt d'arribada"])},
      "pertenceSuperGrupo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pertanys al supergrup"])},
      "msjPuntoLlegado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Els supergrups només es tindran en compte per a edificis propers a causa del punt d'arribada"])}
    },
    "plazasLibres": {
      "modal": {
        "tituloSingular": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Encara hi ha ", _interpolate(_named("plazasLibres")), " plaça lliure en la teva reserva!"])},
        "tituloPlural": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Encara hi ha ", _interpolate(_named("plazasLibres")), " places lliures en la teva reserva!"])},
        "bodySingular": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["En la teva reserva cap ", _interpolate(_list(0)), " persona més. S'afegirà un suplement de ", _interpolate(_list(1)), " a cada persona confirmada. Si finalment es completen les places lliures es retornarà el suplement."])},
        "bodyPlural": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["En la teva reserva caben ", _interpolate(_list(0)), " persones més. S'afegirà un suplement de ", _interpolate(_list(1)), " a cada persona confirmada. Si finalment es completen les places lliures es retornarà el suplement."])},
        "addSuplemento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afegir suplement a la reserva"])},
        "genteReservar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falta gent per reservar"])},
        "completarPlazas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completar places"])}
      }
    },
    "grupo": {
      "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grup"])},
      "msjGrupo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agrupa la teva reserva amb altres. Els grups es tindran en compte de cara als serveis de l'experiència, per exemple a l'hora de distribuir els transports. Per crear un grup envia l'enllaç als usuaris de les altres reserves."])},
      "miembrosGrupo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membres del grup"])}
    },
    "bono": {
      "descargarBono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descarregar bo"])},
      "fechaIni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data inici"])},
      "fechaFin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data fi"])},
      "direccion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adreça"])},
      "informacionGeneral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informació general"])},
      "checkIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check In"])},
      "checkOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check Out"])},
      "regimen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Règim"])},
      "impuestosIncluidos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostos inclosos"])},
      "incluidoPaqueteBasico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inclòs en paquet bàsic"])},
      "servicioExtra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servei Extra"])},
      "localizador": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Localitzador"])},
      "titular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titular"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email de contacte"])}
    },
    "informacionViaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["més informació de l'allotjament"])},
    "informacionFestival": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Més informació del festival"])},
    "msjFechaLimite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La data límit de pagament és el dia"])},
    "msjPagoRealizado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja s'ha realitzat el pagament de la seva experiència. Estigui atent per si es publiquen festes."])},
    "msjNoPagos": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Encara tens ", _interpolate(_named("pendientePago")), _interpolate(_named("currency")), " pendent de pagament."])},
    "reservasDe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserva/es de"])},
    "mantenerReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mantenir Reserva"])},
    "msjSolucionReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podem ajudar-te a trobar una solució alternativa si has de fer canvis en la teva reserva"])},
    "proximosViajes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pròximes experiències:"])},
    "condicionesReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les condicions de reserva per a l'experiència són les següents"])},
    "concepto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concepte"])},
    "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripció"])},
    "precio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preu"])},
    "recuperarReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recuperar reserva"])},
    "codViaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aquest és el codi de la teva experiència"])},
    "mensajeThankYouSingular": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tens ", _interpolate(_named("plazaslibres")), " plaça lliure, comparteix l'enllaç per completar-les o s'afegirà un suplement de ", _interpolate(_named("suplemento")), " a cada persona confirmada."])},
    "mensajeThankYouPlural": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tens ", _interpolate(_named("plazaslibres")), " places lliures, comparteix l'enllaç per completar-les o s'afegirà un suplement de ", _interpolate(_named("suplemento")), " a cada persona confirmada."])},
    "mensajeThankYouCompleto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La teva reserva es compon de les següents persones."])},
    "mensajeThankYouAnular": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Si no es completa abans del ", _interpolate(_named("fechaLimite")), " es cancel·larà automàticament."])},
    "mensajeFechaLimite": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Data límit de pagament: ", _interpolate(_named("fechaLimite")), "."])},
    "repartirCoste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Si no es completen les places lliures s'afegirà un suplement a cada persona confirmada."])},
    "enlacePersonas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En acabar la reserva, envia l'enllaç que et proporcionarem a la resta de persones perquè s'uneixin."])},
    "mensajeExperiencia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estem desitjant veure't i gaudir de l'experiència junts."])},
    "msjAnadirExtras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pots afegir alguns extres si se t'han oblidat"])},
    "anadirExtras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afegir extres"])},
    "masExtras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Més extres"])},
    "extras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extres"])},
    "serviciosContrados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serveis Contractats"])},
    "realizarPago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Realitzar Pagament"])},
    "cambioHabitacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canvi d'habitació"])},
    "informesTotales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informes Totals"])},
    "cancelacionEntre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["si la cancel·lació es produeix entre el "])},
    "costesAsociadosReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les despeses associades a la cancel·lació de la reserva són:"])},
    "msjReservaDescartada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La reserva va ser descartada"])},
    "msjReservaActiva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualment ja tens una reserva en aquesta experiència, tria una altra experiència o consulta les teves reserves"])},
    "descuento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descompte"])},
    "descuentoCod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codi de descompte"])},
    "descuentoCodIntrod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introdueix-lo aquí"])},
    "descuentoCodError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El codi de descompte '", _interpolate(_named("codigo")), "'. No és vàlid."])},
    "paqueteBasico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paquet Bàsic"])},
    "suplementosGastos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suplementos i despeses"])},
    "msjPendientePagoReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disposes d'una reserva pendent de pagament per a aquesta experiència. Vols descartar-la o continuar amb ella?"])},
    "revisaDatos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisa les dades de la teva reserva"])},
    "pagarTotalReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagar l'import total de la reserva"])},
    "enlaceUnirse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En acabar la reserva, envia l'enllaç que et proporcionarem a la resta de persones perquè s'uneixin"])},
    "recuerdaPagar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recorda que pots pagar la reserva íntegra o només una part"])},
    "pagarAhora": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagar ara"])},
    "abonarAntesDe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i abonar la quantitat restant abans del"])},
    "pagarPlazos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagar a terminis."])},
    "pagarTotalRestante": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagar tot l'import restant de la reserva"])},
    "reservaCancelada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserva cancel·lada"])},
    "msjCheckInReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per agilitzar el procés de check-in a la teva arribada, és necessari que omplis la teva informació, pots omplir les teves dades o totes les de la teva mateixa habitació/apartament"])},
    "msjDatosReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pots consultar les dades de la reserva de les persones de les quals vas fer el primer pagament"])},
    "disponeCodReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disposes d'un codi de reserva"])},
    "msjFiesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algú ha dit festa"])},
    "msjNoFiesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encara no hi ha festes disponibles per a cap de les teves experiències. Tan aviat com es confirmin se't notificarà per email perquè puguis entrar a reservar la teva plaça."])},
    "msjAmigoReservado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si tens un amic que ja ha reservat i vols unir-te a la seva habitació, fes clic en el següent botó"])},
    "gastos": {
      "gasto": {
        "tasa-turistica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Despeses per taxa turística"])},
        "gestion": {
          "extras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Despeses extres de gestió"])}
        }
      },
      "FEE_PLATAFORMA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Despeses de gestió de la plataforma"])},
      "incremento": {
        "camas": {
          "vacias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Despesa per resta d'allotjament"])}
        }
      },
      "recargo": {
        "pago": {
          "tardio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Despesa per pagament tardà"])}
        }
      },
      "complemento": {
        "plazas": {
          "libres": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suplement per places lliures"])}
        }
      }
    },
    "visualizacionOcupantes": {
      "persona": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persona"])},
      "plaza": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plaça"])},
      "libre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lliure"])}
    }
  },
  "rooming": {
    "miRooming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El meu rooming"])},
    "resena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deixa'ns una ressenya"])},
    "siguenos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segueix-nos"])},
    "gestorRooming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestor de rooming"])},
    "tooltipAnadirIntegrantes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un cop hagis premut aquí selecciona els integrants a la taula dreta"])},
    "organizarDistribucion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organitzar distribució"])},
    "tooltipMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona l'habitació que vulguis distribuir i després les persones que vulguis posar en aquesta habitació de la taula esquerra"])},
    "cardHabitacion": {
      "anadirIntegrantes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+ Afegir integrants"])},
      "selectFromTable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona els integrants de la taula"])}
    },
    "estado": {
      "NUEVO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nou"])},
      "EN_EDICION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En edició"])},
      "PUBLICADO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicat"])}
    },
    "tabla": {
      "nombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
      "apellidos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cognoms"])},
      "alojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allotjament"])},
      "sinRepartir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sense repartir"])},
      "repartidos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repartits"])},
      "buscarNombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cercar nom"])},
      "guardar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
      "descartar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descartar"])},
      "aceptar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceptar"])}
    },
    "enviar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])},
    "hasTerminado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has acabat?"])},
    "guardarSalir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar i sortir"])},
    "guardarSalirMensaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si no has acabat la distribució i desitges continuar més tard prem a “Guardar i sortir”. Si ja has acabat prem a “Enviar”, tingues en compte que un cop enviada no es podrà realitzar cap canvi."])},
    "error": {
      "publicar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error en publicar el teu rooming"])},
      "guardar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error en guardar el teu viatge"])}
    }
  },
  "alojamiento": {
    "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allotjament"])},
    "mejoresOfertas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les millors ofertes"])},
    "nuestrasExperiencias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les nostres experiències"])},
    "verTodasExperiencias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veure totes"])},
    "imagenes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imatges"])},
    "verImagenes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veure imatges"])},
    "eligeDistancia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tria la distància a pistes (m)"])},
    "eligeTipo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tria el tipus d'allotjament al qual t'agradaria anar"])},
    "eligePrecio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tria el rang de preu del teu viatge"])},
    "galeria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Galeria"])},
    "compartir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compartir"])},
    "tipos": {
      "galeria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Galeria"])},
      "apartamento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apartament"])},
      "hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel"])},
      "albergue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alberg"])},
      "estudio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estudi"])},
      "no aplica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No aplica"])}
    },
    "seleccion_buscador": {
      "desde": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Des de"])},
      "descripcion_extras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["et donem l'opció que cada persona triï els seus extres a mida"])},
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viatges a mida"])}
    },
    "ordenacion": {
      "masEconomicos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Més econòmics"])},
      "menosEconomicos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menys econòmics"])},
      "mejorValorados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Millor valorats"])},
      "menosValorados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menys valorats"])}
    },
    "habitaciones": {
      "singular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["habitació"])},
      "plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["habitacions"])}
    },
    "nombreTitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "filtros": {
      "titulo": {
        "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrar allotjaments"])},
        "reestablecer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reestablir filtres"])}
      },
      "tipos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tria el tipus d'allotjament al qual t'agradaria anar"])},
      "precios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tria el rang de preu del teu viatge"])},
      "distancia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tria la distància a pistes (m)"])}
    },
    "resultados": {
      "encontradoUno": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["S'ha trobat ", _interpolate(_named("count")), " allotjament"])},
      "encontradosVarios": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["S'han trobat ", _interpolate(_named("count")), " allotjaments"])},
      "encontradosMuchos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Més de 50 viatges disponibles"])},
      "sinResultados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No s'han trobat resultats amb la teva cerca, pots realitzar una nova consulta."])},
      "sinResultadosFechas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No disposem d'allotjaments disponibles en les dates seleccionades, et recomanem que realitzis una nova cerca. Pots buscar la disponibilitat dins dels nostres viatges, si no has trobat res, escriu-nos i et recomanem."])}
    },
    "nuestrosViajes": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["els nostres viatges"])},
      "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uneix-te als nostres viatges amb els teus amics i coneix una comunitat de viatgers com tu. A què esperes per unir-te a les millors experiències a la neu?"])},
      "coincidencia": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La teva cerca coincideix en dates amb algun de "])},
        "verCoincidencia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veure coincidència"])},
        "seguirBuscando": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguir amb la meva cerca"])}
      },
      "queEs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Què són els nostres viatges?"])}
    },
    "filtro": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtre Viatges"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrar allotjaments"])},
      "reestablecer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reestablir filtres"])}
    },
    "buscador": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cercador Viatges"])}
    },
    "porPersona": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per persona"])},
    "valoracion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valoració"])},
    "masInformacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Més informació de l'allotjament"])},
    "diasForfait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dies de forfait del"])},
    "estrellas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estrelles"])},
    "distancia": {
      "a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A"])},
      "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de"])}
    },
    "precio": {
      "desde": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Des de"])},
      "porPersona": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per persona"])}
    },
    "modales": {
      "nuestrosViajes": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Els nostres viatges"])},
        "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uneix-te als nostres viatges amb els teus amics i coneix una comunitat de viatgers com tu. A què esperes per unir-te a les millors experiències a la neu?"])},
        "coincidencia": {
          "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La teva cerca coincideix en dates amb algun dels nostres viatges"])},
          "verCoincidencia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veure coincidència"])},
          "seguirBuscando": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguir amb la meva cerca"])}
        }
      },
      "filtros": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtre Viatges"])},
        "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrar allotjaments"])},
        "reestablecer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reestablir filtres"])},
        "tipos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tria el tipus d'allotjament al qual t'agradaria anar"])},
        "poblaciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tria la població a la qual t'agradaria anar"])},
        "precios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tria el rang de preu del teu viatge"])},
        "distancia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tria la distància a pistes (m)"])}
      },
      "buscador": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cercador Viatges"])}
      }
    },
    "sinResultados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No s'han trobat resultats amb la teva cerca, pots realitzar una nova consulta."])},
    "sinResultadosFechas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No disposem d'allotjaments disponibles en les dates seleccionades, et recomanem que realitzis una nova cerca. Pots buscar la disponibilitat dins dels nostres viatges, si no has trobat res, escriu-nos i et recomanem."])},
    "forfait": {
      "dias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dies de forfait del"])},
      "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en"])},
      "al": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["al"])},
      "distancia": {
        "a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A"])},
        "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de l'estació d'esquí"])}
      }
    },
    "lineUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Line up confirmat"])},
    "compartirLanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mira el viatge que he trobat!"])},
    "consigueEntrada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aconsegueix la teva entrada"])},
    "verMas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MOSTRAR MÉS >"])},
    "verMenos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MOSTRAR MENYS >"])},
    "consigueAbono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aconsegueix el teu abonament"])},
    "todasExperiencias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veure totes les experiències"])},
    "proximamente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pròximament..."])},
    "verFoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veure totes les fotos"])},
    "verMapa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veure mapa"])},
    "verMapaAlojamientos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VEURE MAPA ALLOTJAMENTS"])},
    "verUbicaciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veure ubicacions"])},
    "servicios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serveis"])},
    "nombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l'allotjament"])},
    "ofertas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ofertes"])},
    "preguntasFrecuentes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preguntes freqüents"])},
    "horariosCondiciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horaris i condicions especials"])},
    "checkIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check in"])},
    "checkOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check out"])},
    "distancias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distàncies"])},
    "accesoPistas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accés a pistes"])},
    "puntoInteres": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punts d'interès"])},
    "descripcionAlojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripció de l'allotjament"])},
    "regimenDisponibles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Règims disponibles i tipus d'habitació"])},
    "deEstacionEsqui": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de l'estació d'esquí"])},
    "cancelacionGratis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel·lació GRATIS"])},
    "parcialmenteReembolsable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parcialment reemborsable"])},
    "otrasOpcionesViaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altres opcions d'experiència"])},
    "servicioHotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serveis d'Hotel"])},
    "ubicacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubicació"])},
    "fechaAlojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data d'Allotjament"])},
    "filtrosAlojamientos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtre Allotjaments"])}
  },
  "validaciones": {
    "pagador": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagador: "])},
    "validadaError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ERROR: ENTRADA JA VALIDADA"])},
    "validadaExito": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ENTRADA VALIDADA AMB ÈXIT"])},
    "validadaFecha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validada el "])},
    "aLas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" a les "])},
    "cantidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantitat"])},
    "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripció"])},
    "unidades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unitats"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "preguntaValidar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vols validar l'entrada?"])},
    "validar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validar"])}
  },
  "general": {
    "y": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i"])},
    "a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a"])},
    "el": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El"])},
    "aqui": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aquí"])},
    "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de"])},
    "teneis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teniu"])},
    "viaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viatge"])},
    "grupo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grup"])},
    "antesDel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["abans del"])},
    "ahora": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ara"])},
    "checkInOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check In Online"])},
    "checkInRealizado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check In Realitzat"])},
    "codigoCopiado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codi copiat"])},
    "pagoPendiente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagament pendent"])},
    "articulosRelacionados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Articles relacionats"])},
    "mejoresOfertas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Millors ofertes"])},
    "cancelacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel·lació"])},
    "verMas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veure més"])},
    "verMenos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veure menys"])},
    "pagos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagaments"])},
    "atras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enrere"])},
    "miPerfil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El meu perfil"])},
    "siguiente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Següent"])},
    "ordenar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordenar per"])},
    "anios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["anys"])},
    "buscar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cercar"])},
    "opcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opció"])},
    "experiencia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experiència"])},
    "mostrarMas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar més"])},
    "viajeAMedida": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experiències a mida"])},
    "mostrarMenos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar menys"])},
    "cerrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tancar"])},
    "cerrarSesion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tancar sessió"])},
    "solucion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solució"])},
    "para": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per a"])},
    "pagado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagat"])},
    "desde": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["des de"])},
    "seleccion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecció"])},
    "terminosCondiciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termes i Condicions"])},
    "proteccionDatos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protecció de Dades Personals"])},
    "porPersona": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per persona"])},
    "masInformacionViaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Més informació d'aquesta experiència"])},
    "masInformacionAlojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Més informació allotjament"])},
    "variasOcupaciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diverses ocupacions disponibles"])},
    "eligeAlojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tria el teu allotjament"])},
    "quieroSaberMas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vull saber més"])},
    "verTodos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veure tots"])},
    "reservarAlojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservar allotjament"])},
    "unirseHabitacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unir-se a habitació"])},
    "servicios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serveis"])},
    "ubicacionesAlojamientos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veure mapa"])},
    "verUbicaciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veure ubicacions"])},
    "bookingLetterButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tornar"])},
    "codigoInvalido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El codi no és vàlid."])},
    "bookingBusDeparture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sortida"])},
    "experienceFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viatges per a"])},
    "agotado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esgotat"])},
    "dias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dies"])},
    "dia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dia"])},
    "noches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nits"])},
    "noche": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nit"])},
    "del": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["del"])},
    "al": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["al "])},
    "aplicar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplicar"])},
    "resumen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resum"])},
    "plazaUno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["plaça"])},
    "plaza": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["places"])},
    "cantidadDisponibles": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Més de ", _interpolate(_named("quantitat")), " disponibles"])},
    "disponibles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["disponibles"])},
    "agotadas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esgotades"])},
    "reservar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservar"])},
    "listaEspera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Llista d'espera"])},
    "listaEsperaSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La teva petició s'ha afegit a la llista d'espera satisfactòriament"])},
    "listaEsperaError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No ha estat possible afegir a la llista d'espera"])},
    "personas": {
      "singular": {
        "adulto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["adult"])},
        "nino": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nen"])},
        "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["persona"])}
      },
      "plural": {
        "adulto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["adults"])},
        "nino": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nens"])},
        "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["persones"])}
      },
      "mayus": {
        "adulto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adult"])},
        "nino": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nen"])},
        "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persona"])}
      }
    },
    "alojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allotjament"])},
    "gastos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Despeses"])},
    "habitacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Habitació"])},
    "habitaciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Habitacions"])},
    "regimen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Règim"])},
    "esMiReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La reserva és per a mi"])},
    "nombreCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilitzarem el nom i cognom del teu perfil"])},
    "persona": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persona"])},
    "menorEdad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sóc menor d'edat"])},
    "si": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sí"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "masInformacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Més informació"])},
    "masInformacionIcon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+ informació"])},
    "borrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esborrar"])},
    "esMenorEdad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["És menor d'edat"])},
    "fecha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
    "invalidCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codi Invàlid"])},
    "politicaPrivacidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termes i condicions"])}
  },
  "filtroAlojamiento": {
    "borrarFiltros": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esborrar filtres"])},
    "titulo": {
      "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrar allotjament"])},
      "poblacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poblacions"])},
      "alojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipus d'allotjament"])},
      "plaza": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nº places"])},
      "reestablecer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reestablir filtres"])}
    }
  },
  "filtroModalidades": {
    "titulo": {
      "seleccionaGama": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar nivell"])},
      "seleccionaServicio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar servei"])}
    }
  },
  "selectSubTipos": {
    "titulo": {
      "seguroAnulacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assegurança d'Anul·lació"])},
      "seguroViaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assegurança d'experiència"])}
    }
  },
  "resumenServiciosBuscador": {
    "nochesAlojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dies d'allotjament"])},
    "diasForfait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dies de forfait"])}
  },
  "baseMigas": {
    "seleccionAlojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecció d'allotjament"])},
    "datosReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserva"])},
    "confirmacionReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmació reserva"])},
    "pago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagament"])},
    "seleccionFestival": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecció de festival"])}
  },
  "botones": {
    "miEntrada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La meva entrada"])},
    "reservarAlojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservar allotjament"])},
    "reservaPackFiesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservar pack de festa"])},
    "unirseHabitacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unir-se a habitació"])},
    "volverViajes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tornar a experiències"])},
    "volverReservas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tornar a les meves reserves"])},
    "actualizar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualitzar"])},
    "reservar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservar"])},
    "cerrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tancar"])},
    "verTodos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veure tots"])},
    "verDetalles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veure detalls"])},
    "verReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veure Reserva"])},
    "bonoAdjunto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descarregar Bono Adjunts"])},
    "cancelada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel·lada"])},
    "descargar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descarregar"])},
    "guardar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
    "volver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tornar"])},
    "entrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrar"])},
    "enviar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])},
    "recuperar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recuperar"])},
    "copiar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiar"])},
    "cancelar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel·lar"])},
    "cancelarExperiencia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel·lar experiència"])},
    "modificar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificar"])},
    "modificarDatos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificar dades"])},
    "modificarPagar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificar i Pagar"])},
    "agregarPersona": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afegeix una altra persona"])},
    "aceptar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceptar"])},
    "descargarPdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descarregar PDF"])},
    "buscar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cercar"])},
    "darseBaja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donar-se de baixa"])},
    "aceptarSeleccion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceptar Selecció"])},
    "cancelarReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel·lar Reserva"])},
    "modificarReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificar Reserva"])},
    "descartar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descartar"])},
    "unirse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unir-se"])},
    "reactivarReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reactivar reserva"])},
    "recordar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recordar"])},
    "pagarSenial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagar Senyal"])},
    "pagar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagar"])},
    "establecer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Establir"])},
    "verBono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veure Bono"])},
    "continuar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])},
    "reintentar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reintentar"])}
  },
  "codigoDescuento": {
    "tienesCodDescuento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tens un codi de descompte?"])}
  },
  "cookies": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["POLÍTICA SOBRE COOKIES"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilitzem cookies per millorar els nostres serveis, analitzar i personalitzar la teva navegació. Per continuar navegant per la nostra web, has d'acceptar el seu ús. Pots canviar la configuració o obtenir més informació a la nostra"])},
    "aceptar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepto totes les cookies"])},
    "politicas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de Cookies"])},
    "gestionar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestionar cookies"])},
    "extras": {
      "necesarias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Necessàries"])},
      "analiticas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analítiques"])},
      "marketing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Màrqueting"])}
    },
    "preguntas": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QUÈ SÓN LES COOKIES?"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AUTORITZACIÓ PER A L'ÚS DE COOKIES?"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TIPUS DE COOKIES EMPLEADES?"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COM DESACTIVAR O ELIMINAR LES COOKIES?"])}
    },
    "respuestas": {
      "1": {
        "bloque1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les cookies són fitxers de text que s'emmagatzemen en el dispositiu de l'usuari que navega a través d'Internet i que, en particular, conté un número que permet identificar de manera única aquest dispositiu, encara que aquest canviï de localització o d'adreça IP."])},
        "bloque2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les cookies s'instal·len durant la navegació per Internet, bé pels llocs web que visita l'usuari o bé per tercers amb els quals es relaciona el lloc web, i permeten a aquest conèixer la seva activitat en el mateix lloc o en altres amb els quals es relaciona aquest, per exemple: el lloc des del qual accedeix, el temps de connexió, el dispositiu des del qual accedeix (fix o mòbil), el sistema operatiu i navegador utilitzats, les pàgines més visitades, el nombre de clics realitzats i de dades respecte al comportament de l'usuari a Internet."])},
        "bloque3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les cookies s'associen únicament al seu navegador i no proporcionen per si mateixes dades personals. Les cookies no poden danyar el seu dispositiu i a més són molt útils, ja que ens ajuden a identificar i resoldre errors. El lloc web és accessible sense necessitat que les cookies estiguin activades, si bé, la seva desactivació pot impedir el correcte funcionament del mateix."])}
      },
      "2": {
        "bloque1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De conformitat amb l'avís de cookies que apareix al peu de pàgina del lloc web, l'usuari o visitant dels mateixos accepta que, en navegar pel mateix, consent expressament l'ús de cookies segons la descripció que es detalla a continuació, excepte en la mesura que hagi modificat la configuració del seu navegador per rebutjar la utilització de les mateixes."])}
      },
      "3": {
        "bloque1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El Titular de la web utilitza cookies estrictament necessàries que serveixen per facilitar la correcta navegació en el lloc Web, així com per assegurar que el contingut dels mateixos es carrega eficaçment. Aquestes cookies són, a més, cookies de sessió és a dir que tenen caràcter temporal i expiren i es borren automàticament quan l'usuari tanca el seu navegador."])},
        "bloque2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookies de Facebook Ireland Lt, que s'utilitzen perquè els visitants puguin interactuar amb el contingut de Facebook i que es generen únicament per als usuaris d'aquestes xarxes socials. Les condicions d'utilització d'aquestes cookies i la informació recopilada es regula per la política de privacitat de la plataforma social corresponent."])}
      },
      "4": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les Cookies es poden desactivar i eliminar seguint les següents guies en funció del navegador emprat:"])},
        "bloque1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar i administrar cookies a Internet Explorer"])},
        "bloque2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esborrar, habilitar i administrar cookies a Chrome"])},
        "bloque3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esborrar cookies a Firefox"])},
        "bloque4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuració web de Safari a l'iPhone, l'iPad i l'iPod touch"])}
      }
    }
  },
  "politicasCancelacion": {
    "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polítiques de Cancel·lació"])}
  },
  "titulos": {
    "incluye": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incluïts"])},
    "itinerario": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Itinerari"])},
    "extras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extres"])},
    "seleccionServicios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserva per a tantes persones com vulguis"])},
    "completarReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omple les següents dades per continuar amb la teva reserva"])},
    "motivosCancelacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motius de cancel·lació"])},
    "editarPerfil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar Perfil"])}
  },
  "subtitulo": {
    "seleccionServicios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En acabar la reserva, envia l'enllaç que et proporcionarem a la resta de persones perquè s'uneixin."])},
    "nombreHabitacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introdueix el nom de la teva habitació"])},
    "comoConociste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Com ens vas conèixer?"])},
    "estudiando": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estàs estudiant?"])},
    "escribeRespuesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escriu la teva resposta"])},
    "eligeUniversidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tria una universitat"])},
    "trabajando": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En quina empresa estàs treballant?"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "importeTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import total"])},
    "importePagado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import total pagat"])},
    "importePendiente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import pendent"])},
    "totalReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total reserva"])},
    "totalPagar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TOTAL a pagar"])},
    "saldoPendiente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo pendent"])},
    "codigoReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codi de reserva"])},
    "codigoViaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codi d'experiència"])},
    "intoduceAqui": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introdueix-lo aquí"])},
    "codigo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codi"])},
    "misViajeros": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Els meus viatgers"])},
    "miPerfil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El meu perfil"])},
    "mailingPublicidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mailing publicitat"])},
    "comercial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom del comercial"])},
    "subirDni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pujar DNI/Passaport"])},
    "modificarcontrasenia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificar contrasenya"])},
    "volverPerfil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tornar al meu perfil"])},
    "datos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dades"])}
  },
  "modalUnirseHabitacion": {
    "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["T'has quedat sense allotjament?"])},
    "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omple les següents dades i en cas de quedar lliure alguna habitació se t'avisarà"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vols unir-te a l'habitació d'una experiència, utilitza el codi que t'ha donat la persona que hagi realitzat la reserva"])}
  },
  "input": {
    "field": {
      "nombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
      "apellido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cognom"])},
      "nombreApellidos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom i cognoms"])},
      "correo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correu electrònic"])},
      "telefono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telèfon"])},
      "numeroPlazas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de places"])},
      "observaciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Observacions"])},
      "redesSociales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xarxes socials"])},
      "comerciales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comercials"])},
      "paqueteBasico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paquet bàsic"])}
    },
    "placeholder": {
      "nombreHabitacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escriu el nom de la teva habitació"])},
      "introduceCampo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introdueix el camp"])}
    }
  },
  "login": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dades del comprador"])},
    "subtitleRegister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No ets client? Registra't"])},
    "forgetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has oblidat la teva contrasenya"])},
    "recoverPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recuperar contrasenya"])},
    "questionLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja ets client? Inicia sessió"])},
    "questionUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja ets usuari"])},
    "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar la contrasenya"])},
    "establecerContrasenia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Establir contrasenya d'usuari"])},
    "entrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrar"])}
  },
  "experiencia": {
    "IntroduceCodViaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introdueix el codi de la teva experiència"])},
    "colegios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si tens entre 5 i 17 anys i vols aprofitar al màxim els dies d'esquí, aquesta és la teva experiència. Millora la teva tècnica i gaudeix de la natura, nosaltres et facilitem el transport, allotjament i monitors."])},
    "familias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["T'oferim l'allotjament més còmode per viatjar a la neu amb la teva família. A més, podràs contractar classes d'esquí per a nens i moltes altres activitats adaptades a les teves necessitats."])},
    "trabajadores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viatjar amb els teus companys de feina és molt fàcil. Si tens més de 25 anys, uneix-te a les nostres experiències organitzades per coincidir amb gent com tu, en les quals l'esquí i l'oci nocturn et sorprendran."])},
    "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aquesta és la teva experiència si vols coincidir amb gent de la teva edat en els millors festivals i après-skis. T'atreveixes a viure una experiència inoblidable a la neu?"])}
  },
  "errors": {
    "habitacion": {
      "codigoNotMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El codi no correspon a cap habitació"])}
    },
    "grupoReservas": {
      "pendientes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hi ha reserves pendents de confirmar"])}
    },
    "experiencia": {
      "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'experiència no admet noves reserves"])}
    },
    "reserva": {
      "alreadyExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un dels viatgers ja té una reserva en aquesta experiència"])}
    },
    "servicios": {
      "cuposInsuficientes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alguns dels serveis seleccionats estan esgotats"])}
    },
    "pago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'ha produït un error en realitzar el pagament"])},
    "confirmacionPago": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El teu pagament està en procés de verificació."])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hi ha hagut un problema de comunicació amb la passarel·la de pagament. Aquesta incidència s'hauria de resoldre automàticament en unes hores. Si en 24 hores la teva reserva no apareix al teu perfil, si us plau, posa't en contacte amb nosaltres."])}
    },
    "pagoTransferencia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El pagament d'aquesta reserva és per transferència"])},
    "cargaInformacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hi ha hagut un problema en carregar la informació. Intenta-ho més tard"])},
    "controlErrorValidarCupo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No existeix l'experiència sol·licitada"])},
    "noAdmiteReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'experiència no admet una reserva nova"])},
    "precioCambiado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'ha detectat un canvi de preus"])},
    "inesperado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error inesperat. Intenta-ho més tard"])},
    "loginReservaDescartar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Necessites iniciar sessió per descartar la reserva"])},
    "permisosDescartarReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No tens els permisos per descartar la reserva"])},
    "descartarReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No es pot descartar la reserva"])},
    "intentalo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error inesperat. Intenta-ho més tard"])},
    "obligatorios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hi ha camps obligatoris sense omplir"])},
    "loginReservaCrear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Necessites iniciar sessió per crear la reserva"])},
    "permisosCrearReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No tens els permisos per crear la reserva"])},
    "noInfoReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No s'ha pogut trobar la informació de la reserva"])},
    "existeReservaNombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja existeix una reserva pagada amb aquest nom."])},
    "existeReservaViaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja existeix una reserva per aquesta experiència"])},
    "emailRegistrado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El correu electrònic ja està registrat"])},
    "permisosProcesos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No tens els permisos per realitzar el procés"])},
    "camposSinCompletar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hi ha camps sense completar"])},
    "recursoNoLocalizado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El recurs sol·licitat no ha pogut ser localitzat"])},
    "conflictoEstadoReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hi ha un conflicte en l'estat de la reserva"])},
    "errorContrasenia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error en introduir la contrasenya"])},
    "loginRequerido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Necessites iniciar sessió per continuar"])},
    "reservaNoPendiente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La reserva no es troba en estat pendent"])},
    "noAlojamientos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No s'han trobat allotjaments"])},
    "intenteReservaTarde": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si us plau, intenta reservar més tard, en cas que l'error persisteixi contacta'ns."])}
  },
  "servicios": {
    "repercutirFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxa de gestió"])},
    "tasaDe": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Taxa de ", _interpolate(_named("nombreTasa"))])},
    "forfait": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forfait"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pots canviar els dies del teu forfait. Selecciona els dies que vulguis"])}
    },
    "comida": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menjar"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tria si vols menjar a pistes"])}
    },
    "transporte": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transport"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tria el teu mitjà de transport"])}
    },
    "seguro": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assegurances"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tria la teva assegurança"])},
      "verPoliza": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veure pòlissa"])},
      "subtipo": {
        "cancelacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel·lació"])},
        "salud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salut"])}
      }
    },
    "actividad": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activitats"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tria l'activitat"])}
    },
    "restauracion": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menjar"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tria si vols menjar a pistes"])}
    },
    "festival": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Festivals"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tria l'entrada que vulguis i vine a gaudir de l'experiència completa"])}
    },
    "clase": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classes"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pots triar els dies de classes. Selecciona els dies que vulguis"])}
    },
    "alquiler": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lloguers"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tria si vols llogar equip"])}
    },
    "greenFee": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Green Fee"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tria si vols Green Fee."])}
    },
    "relax": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relax"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tria si vols servei de relax."])}
    },
    "personal": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tria el personal."])}
    },
    "parking": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aparcament"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona el teu aparcament preferit"])}
    },
    "mascota": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mascota"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tria els serveis per a mascotes"])}
    },
    "competicion": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competició"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tria la competició"])}
    },
    "tooltipMessageIsNominal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alguns serveis són nominals, el que implica que només es pot seleccionar un per persona"])},
    "servicioNominal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servei nominal"])},
    "noDisponibilidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hi ha més disponibilitat per aquest servei"])},
    "maximoPermitido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has assolit el màxim nombre de serveis per persona"])}
  },
  "trabajaNosotros": {
    "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treballa amb Nosaltres"])}
  },
  "cardReservaPlazasLibres": {
    "descripcion": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Encara tens ", _interpolate(_named("plazasLibres")), " places lliures en el teu allotjament, el que suposa un pagament pendent de ", _interpolate(_named("pagoPendiente")), ". Tens dues opcions:"])},
    "opcionUno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compartir l'enllaç per completar les places lliures."])},
    "opcionDos": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Abonar el pagament pendent de ", _interpolate(_named("pagoPendiente")), " entre les persones confirmades."])},
    "cancelacion": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Si no es porta a terme una de les dues opcions abans del ", _interpolate(_named("fecha")), " la ", _interpolate(_named("final"))])},
    "cancelacionBold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reserva es cancel·larà automàticament."])},
    "msjPlazaLibresPagadas": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tens ", _interpolate(_named("plazasLibres")), " places lliures, comparteix l'enllaç per completar-les i el suplement “Places lliures” es recalcularà a favor teu."])},
    "button": {
      "abonarPago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonar el pagament pendent"])},
      "copiarLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiar Enllaç"])}
    }
  },
  "langs": {
    "es": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espanyol"])},
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anglès"])},
    "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Francès"])},
    "it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italià"])},
    "ca": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Català"])}
  }
}