import SkiAndNightApiService from './BaseSkiAndNightApiService';

const VIAJES_GRUPO_API_VERSION = 'v1';
const VIAJES_GRUPO_API_BASEPATH_URL = '/viajesGrupo/' + VIAJES_GRUPO_API_VERSION;

class ViajesGrupoApiService {
  async getFiltroViajes() {
    return await SkiAndNightApiService.get(VIAJES_GRUPO_API_BASEPATH_URL.concat('/filtroViajes'));
  }

  async getViajes() {
    return await SkiAndNightApiService.get(VIAJES_GRUPO_API_BASEPATH_URL.concat('/viajes'));
  }

  async getViaje(viajeId, codigoViaje, codigoPrevisualizacion) {
    return await SkiAndNightApiService.get(VIAJES_GRUPO_API_BASEPATH_URL.concat('/viajes/' + viajeId), {
      codigo: codigoViaje,
      codigoPrevisualizacion: codigoPrevisualizacion,
    });
  }

  async getAlojamientosViaje(viajeId) {
    return await SkiAndNightApiService.get(
      VIAJES_GRUPO_API_BASEPATH_URL.concat('/viajes/' + viajeId + '/alojamientos')
    );
  }

  /**
   * Devuelve todos los extras disponibles para una viaje agrupados por tipo
   *
   * @param viajeId viaje Id
   * @returns {Promise<*>} VGExtrasCategorizadosDTO Agrupacion de extras de un mismo tipo
   */
  async getServicios(viajeId) {
    return await SkiAndNightApiService.get(VIAJES_GRUPO_API_BASEPATH_URL.concat('/viajes/' + viajeId + '/servicios'));
  }

  async checkCompatibility(viajeId) {
    return await SkiAndNightApiService.get(VIAJES_GRUPO_API_BASEPATH_URL.concat(`/viajes/${viajeId}/compatibility`));
  }
  async getValidarCupo(
    viajeId,
    alojamientoId,
    tipoHabitacionId,
    numPlazas,
    precioTotal,
    incrementoAlojamiento,
    regimen,
    integrado
  ) {
    return await SkiAndNightApiService.get(
      VIAJES_GRUPO_API_BASEPATH_URL.concat(
        `/viajes/${viajeId}/alojamientos/${alojamientoId}/validaCupo?tipoHabitacionId=${tipoHabitacionId}&numPlazas=${numPlazas}&precioTotal=${precioTotal}&incrementoAlojamiento=${incrementoAlojamiento}&regimen=${regimen}&integrado=${integrado}`
      )
    );
  }

  async getFiltroServicios(viajeId) {
    return await SkiAndNightApiService.get(
      VIAJES_GRUPO_API_BASEPATH_URL.concat('/viajes/' + viajeId + '/servicios/filtro')
    );
  }

  async validarDescuento(viajeId, codigo, reservasIds) {
    var params = new URLSearchParams();
    params.append('codigo', codigo);
    reservasIds.forEach(data => {
      params.append('reservasIds[]', data);
    });

    return await SkiAndNightApiService.get(
      VIAJES_GRUPO_API_BASEPATH_URL.concat('/viajes/' + viajeId + '/validarDescuento'),
      params
    );
  }

  /**
   * Crea una reserva temporal (bloqueada) que en caso de no confirmarse realizando el pago de la misma en un periodo
   * de tiempo determinado, se borrara automaticamente liberando la plaza.
   *
   * @param viajeId Id del viaje
   * @param vgCreacionReservaDTO VGCreacionReservaDTO
   * @returns {Promise<*>} VGReservaDTO
   */
  async crearGrupoReservas(viajeId, vgCreacionReservaDTO) {
    return await SkiAndNightApiService.post(
      VIAJES_GRUPO_API_BASEPATH_URL.concat('/viajes/' + viajeId + '/crearGrupoReservas'),
      vgCreacionReservaDTO
    );
  }

  /**
	 * Devuelve los datos de la reserva del usuario para el viaje pasado para retomar el proceso de confirmacion
     de la misma siempre y cuando este en estado BLOQUEADA
	 *
	 * @param viajeId Id del viaje
	 * @returns {Promise<*>} VGReservaDTO
	 */
  async recuperarGrupoReservas(viajeId) {
    return await SkiAndNightApiService.get(
      VIAJES_GRUPO_API_BASEPATH_URL.concat('/viajes/' + viajeId + '/recuperarGrupoReservas')
    );
  }

  /**
   * Modifica una reserva temporal (bloqueada).
   *
   * @param viajeId Ide del viaje
   * @param vgModificacionReservaDTO VGModificacionReservaDTO
   * @returns {Promise<*>} VGReservaDTO
   */
  async modificarGrupoReservas(viajeId, vgModificacionReservaDTO) {
    return await SkiAndNightApiService.post(
      VIAJES_GRUPO_API_BASEPATH_URL.concat('/viajes/' + viajeId + '/modificarGrupoReservas'),
      vgModificacionReservaDTO
    );
  }

  /**
   * Elimina la reserva del usuario para el viaje pasado siempre y cuando este en estado BLOQUEADA
   *
   * @param viajeId Id del viaje
   * @returns {Promise<*>} 200-OK
   */
  async descartarGrupoReservas(viajeId) {
    return await SkiAndNightApiService.delete(
      VIAJES_GRUPO_API_BASEPATH_URL.concat('/viajes/' + viajeId + '/descartarGrupoReservas')
    );
  }

  /**
   * Devuelve el pdf de la poliza del seguro de viaje
   *
   * @param viajeId Id del viaje
   * @param extraId Id del extra
   * @returns {Promise<*>} El pdf de la poliza String binary
   */
  async getPolizaSeguroViaje(viajeId, extraId) {
    return await SkiAndNightApiService.get(
      VIAJES_GRUPO_API_BASEPATH_URL.concat('/viajes/' + viajeId + '/polizaSeguro/' + extraId),
      {},
      {
        Accept: 'application/pdf, text/plain',
        'Content-Type': 'application/json',
      },
      'blob'
    );
  }

  /**
   * Crea o modifica una lista de espera para un usuario y un viaje
   *
   * @param vgListaEsperaDTO datos de la lista de espera a agreagr o modificar
   * @returns {Promise<*>}
   */
  async agregarListaEspera(vgListaEsperaDTO) {
    return await SkiAndNightApiService.post(VIAJES_GRUPO_API_BASEPATH_URL.concat('/listaEspera'), vgListaEsperaDTO);
  }

  /**
   * Vota en un viaje de una oferta
   *
   * @param idOfertaViaje id del asociado al viaje a votar en la oferta correspondiente
   * @returns {Promise<*>}
   */
  async votarOferta(idOfertaViaje) {
    return await SkiAndNightApiService.get(VIAJES_GRUPO_API_BASEPATH_URL.concat('/ofertas/votar/', idOfertaViaje));
  }

  /**
   * Devuelve los módulos del builder de un viaje
   *
   * @param viajeId Id del viaje
   * @param {string} [codigo] Código del viaje (solo si el viaje es privado)
   * @param {string} [codigoPrevisualizacion] Código de previsualización del viaje
   * @returns {Promise<VGBuilderModuleDetailDTO[]>} El detalle del builder modules de un viaje
   */
  async getBuilderModule(viajeId, codigo, codigoPrevisualizacion) {
    const params = {};
    if (codigo) params.codigo = codigo;
    if (codigoPrevisualizacion) params.codigoPrevisualizacion = codigoPrevisualizacion;

    return await SkiAndNightApiService.get(
      VIAJES_GRUPO_API_BASEPATH_URL.concat('/viajes/' + viajeId + '/builderModules'),
      params
    );
  }
}

export default new ViajesGrupoApiService();
