export default {
  "actions": {
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])}
  },
  "header": {
    "horarioAtencion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lun-Jeu de 9h00 à 18h30, Ven de 9h00 à 14h00"])},
    "ski": {
      "groups": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nos expériences"])},
        "university": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Universitaires"])},
        "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Écoles"])},
        "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travailleurs"])},
        "premium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premium"])},
        "family": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Familles"])},
        "private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupes privés"])}
      },
      "destinations": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinations"])}
      },
      "stations": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stations"])}
      }
    },
    "night": {
      "festivals": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Festivals"])}
      },
      "apres": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Après-ski"])}
      }
    },
    "us": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous"])},
      "whoWeAre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qui sommes-nous?"])},
      "work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travaillez avec nous"])}
    },
    "user": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter"])},
      "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'inscrire"])}
    },
    "login": {
      "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenue!"])},
      "welcomePlanner": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bienvenue à ", _interpolate(_named("planner")), "!"])},
      "continuarGoogle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer avec Google"])},
      "tusDatos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos données"])},
      "restablecerPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser le mot de passe"])},
      "entrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrer"])}
    }
  },
  "searcher": {
    "organized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nos expériences"])},
    "personalized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expériences sur mesure"])},
    "destiny": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Où voulez-vous aller?"])}
    },
    "date": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dates"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez quand vous pouvez partir"])}
    },
    "datesAccomodation": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dates d'hébergement"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez quand vous voulez aller"])}
    },
    "datesForfait": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dates du forfait"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dates du forfait"])}
    },
    "rooms": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chambres"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez les chambres"])}
    },
    "groups": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de groupe"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["D'où venez-vous?"])}
    },
    "resumenReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résumé de la réservation"])}
  },
  "filters": {
    "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtre"])},
    "plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtres"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser"])},
    "alojamiento": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez plus d'hébergement"])},
      "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remplissez les données suivantes et en cas de disponibilité d'une chambre, vous serez informé"])}
    }
  },
  "home": {
    "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accueil"])},
    "banner": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VIVEZ L'EXPÉRIENCE COMPLÈTE"])},
      "titleBsspain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VOS ÉVÉNEMENTS DE SALSA ET BACHATA"])},
      "titlePr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RÉSERVEZ VOS VOYAGES POKER PRÉFLOP"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejoignez nos expériences avec vos amis et découvrez une communauté de voyageurs comme vous. Choisissez une destination, les dates de voyage souhaitées et votre lieu de départ. Qu'attendez-vous pour rejoindre les meilleures expériences sur la neige?"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommander une expérience"])},
      "eligeFecha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez quand voyager"])},
      "experienciasAnyo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expériences 24/25"])},
      "experiencias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expériences"])},
      "eligeTuEvento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez votre événement"])},
      "experienciasAMedida": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expériences sur mesure"])}
    },
    "festivales": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NOS EXPÉRIENCES"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voyagez avec vos amis vers nos destinations préférées où vous pourrez profiter de nos deux événements principaux : AWS Formi et WSF Pas de la Casa."])},
      "titleDefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nos expériences"])},
      "subtitleDefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Découvrez des expériences uniques conçues pour connecter les communautés à travers les voyages et les événements"])},
      "titleBsspain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez votre événement"])},
      "subtitleBsspain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salsa, bachata ou mixte en toute date ou ville"])},
      "titlePr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prochains tournois"])},
      "subtitlePr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voyagez avec vos meilleurs amis vers les meilleurs festivals de poker du pays et alojez-vous toujours près du casino"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dates"])},
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieu"])}
    },
    "buscadorSection": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créez votre expérience sur mesure"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nos voyages de groupe ne vous conviennent pas? Utilisez notre moteur de recherche pour configurer un voyage entièrement sur mesure."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aller au moteur de recherche"])}
    },
    "porqueReservar": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pourquoi réserver avec Ski&Night?"])},
      "skiEnjoy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SKIER, PROFITER"])},
      "sleep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DORMIR, "])},
      "repeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REPEAT"])},
      "texto1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisez votre voyage à la neige vers n'importe quelle destination en Europe."])},
      "texto2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" sont les destinations parfaites pour profiter de la neige et des meilleures soirées."])},
      "texto3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avec Ski&Night, nous vous offrons une expérience unique dans les meilleures destinations en plein paradis naturel. Nous vous offrons"])},
      "texto4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" et l'accès aux festivals pour que vous n'ayez à vous soucier que de vous amuser."])},
      "alojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hébergement, cours"])},
      "material": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["location de matériel"])}
    },
    "packs": {
      "school": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voyages scolaires"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous avez entre 5 et 17 ans et que vous voulez profiter au maximum des journées de ski, c'est votre voyage. Améliorez votre technique et profitez de la nature, nous vous fournissons le transport, l'hébergement et les moniteurs."])}
      },
      "family": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voyages pour les familles"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous vous proposons le logement le plus confortable pour voyager à la neige avec votre famille. De plus, vous pourrez réserver des cours de ski pour les enfants et de nombreuses autres activités adaptées à vos besoins."])}
      },
      "university": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voyages pour les universitaires"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C'est votre voyage si vous voulez rencontrer des gens de votre âge lors des meilleurs festivals et après-ski. Osez vivre une expérience inoubliable dans la neige!"])}
      },
      "company": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voyages pour les travailleurs"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voyager avec vos collègues de travail est très facile. Si vous avez plus de 25 ans, rejoignez nos voyages organisés pour rencontrer des personnes comme vous, où le ski et la vie nocturne vous surprendront."])}
      },
      "premium": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voyages Premium"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réservez un voyage exclusif dans les meilleurs hébergements à la neige en complément des journées de ski avec des expériences uniques dans la neige : gastronomie, détente, sports extrêmes ou expériences culturelles."])}
      }
    },
    "festival": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Winter Snow Festival, notre festival"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chez Ski&Night, nous avons conçu l'expérience Winter Snow Festival, notre festival dans la neige où vous pourrez profiter des DJ et musiciens les plus exclusifs du moment en pleine montagne."])},
      "description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette année, nous célébrons la 4e édition du WSF, une expérience unique dans la neige que vous pourrez apprécier en Andorre aux côtés de plus de 5 000 personnes comme vous."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consultez nos festivals"])}
    },
    "apres": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les meilleurs après-ski"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous sommes spécialistes de l'organisation des meilleures fêtes dans la neige. Après une journée de ski, vous pourrez profiter de bonne musique avec nos DJ dans un cadre incomparable. Découvrez une sélection des après-ski les plus exclusifs :"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir tout"])}
    },
    "experiencias": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les meilleures expériences"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir tout"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes"])}
    },
    "offers": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les meilleures offres"])}
    },
    "stations": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les meilleures stations de ski"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous organisons les meilleures escapades de ski à Formigal, Grandvalira et Sierra Nevada, entre autres stations. Jetez un œil aux stations où nous organisons des voyages et choisissez celle qui vous convient le mieux."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher tout"])}
    },
    "form": {
      "backButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
      "nextButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])},
      "others": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre"])},
      "tamañoMaximo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La taille maximale du fichier à télécharger est de "])},
      "personalInformation": {
        "ADni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recto du passeport"])},
        "alquilerMaterial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location de matériel"])},
        "altura": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taille (cm)"])},
        "anversoReversoDni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recto et verso de la pièce d'identité téléchargés"])},
        "birthDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de naissance"])},
        "codPostal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code postal"])},
        "confirmEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmez votre adresse e-mail"])},
        "passwordRegister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
        "alergias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allergies"])},
        "ocultar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cacher"])},
        "mostrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montrer"])},
        "password8CharReq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au moins 8 caractères"])},
        "password1MayusReq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au moins 1 lettre majuscule"])},
        "password1NumReq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au moins 1 chiffre"])},
        "confirmnewpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmez le nouveau mot de passe"])},
        "contraseniaLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez votre mot de passe pour vous connecter"])},
        "cv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous le souhaitez, vous pouvez nous envoyer votre CV"])},
        "descripcionAlquilerMaterial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les données sont indicatives, vous pourrez les essayer et les modifier en fonction de vos besoins dans le magasin lui-même."])},
        "diaHoraLlegada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jour et heure d'arrivée"])},
        "diaLlegada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jour d'arrivée"])},
        "direccion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
        "dni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passeport"])},
        "welcomeBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenue de nouveau"])},
        "emailOtherLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectez-vous avec un autre compte"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse e-mail"])},
        "emailComercialesActivos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emails commerciaux actifs"])},
        "errorPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur de validation du mot de passe, veuillez vérifier les informations d'identification."])},
        "expedicionDni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de délivrance du passeport"])},
        "hombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Homme"])},
        "horaLlegada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heure d'arrivée"])},
        "horaSalida": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jour et heure de départ"])},
        "howDid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment nous avez-vous connus?"])},
        "ingresaAltura": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez votre taille"])},
        "ingresaPeso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez votre poids"])},
        "ingresaTallaPie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez votre pointure"])},
        "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de famille"])},
        "logeate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectez-vous"])},
        "lugarNacimiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieu de naissance"])},
        "msjADni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il doit s'agir d'un fichier PDF au format PNG, JPG ou GIF"])},
        "msjcontrasenia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour rendre votre mot de passe plus sécurisé, nous vous recommandons d'utiliser une combinaison de majuscules et de minuscules, de chiffres et de caractères spéciaux tels que *, %, !."])},
        "mujer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Femme"])},
        "nacionalidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nationalité"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
        "newpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau mot de passe"])},
        "noTienesCuenta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas de compte?"])},
        "pais": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe actuel"])},
        "peso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poids (kg)"])},
        "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone"])},
        "poblacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
        "provincia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Province"])},
        "RDni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verso du passeport"])},
        "anversoReversoDnis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recto et verso du passeport"])},
        "registrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'inscrire"])},
        "errorRegPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe doit répondre à toutes les exigences de sécurité."])},
        "errorEmailFormal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'e-mail n'a pas un format correct."])},
        "selecciona": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner"])},
        "selectedFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner le document"])},
        "sexo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sexe"])},
        "tallaPie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pointure (EU)"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations personnelles"])},
        "title-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous nous dire quelque chose de plus sur vous?"])},
        "write": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Écrivez ici"])},
        "noRecibirMailing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je ne souhaite pas recevoir de nouvelles et de communications commerciales."])},
        "aceptoMailing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J'accepte de recevoir des nouveautés et des communications commerciales."])},
        "emailLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez votre adresse électronique pour vous connecter ou vous enregistrer"])},
        "dataRegister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez vos informations pour vous inscrire"])},
        "continuar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])},
        "iniciarSesionRegistrarReservar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter ou s'inscrire pour réserver"])},
        "registradoCorrect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistré avec succès"])}
      },
      "policy": {
        "readAccept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J'ai lu et j'accepte les"])},
        "readAccepts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J'ai lu et j'accepte les"])},
        "readAcceptsLos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J'ai lu et j'accepte les"])},
        "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["et les"])},
        "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["termes et conditions"])},
        "freePlacesPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politique des places gratuites"])},
        "policyCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politique d'annulation"])},
        "policyAlojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politique d'hébergement en occupation complète"])},
        "titleCondicionesCancelacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditions et frais d'annulation"])},
        "msgCondiciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les conditions de réservation de l'expérience sont les suivantes:"])},
        "cancelReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulation de la réservation"])},
        "descriptionCondiciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si le client renonce à l'expérience après la formalisation du dépôt exigé pour considérer la réservation comme REMBOURSABLE, il devra payer les montants suivants :"])},
        "descriptionCondicionesNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si le client renonce à l'expérience après la formalisation du dépôt exigé pour considérer la réservation comme NON REMBOURSABLE, il devra payer les montants suivants :"])},
        "detail1Condiciones": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le ", _interpolate(_named("porcentaje")), "% s'il se produit entre ", _interpolate(_named("fechaInicio")), " et avant ", _interpolate(_named("fechaFin"))])},
        "detail2Condiciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100% s'il est dans les 15 jours naturels avant le départ."])},
        "detail3Condiciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La réservation de l'expérience ne sera en aucun cas remboursée"])},
        "detail4Condiciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez également effectuer un changement de titulaire de la réservation pour qu'un collègue vous remplace, ce changement peut être effectué au plus tard 5 jours avant la date de début de l'expérience et peut entraîner des frais."])},
        "detail5Condiciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les frais de gestion liés aux modifications de la réservation ou au non-respect des délais de paiement ne seront en aucun cas remboursables."])},
        "detail1Condiciones1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Du ", _interpolate(_named("fechaInicio")), " au ", _interpolate(_named("fechaFin")), " "])},
        "detail2Condiciones1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les suppléments et frais liés à des modifications de réservation ou dus au non-respect des délais de paiement et des frais ne seront en aucun cas remboursables."])},
        "detail3Condiciones1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous pouvez modifier votre réservation gratuitement jusqu'au ", _interpolate(_named("fecha")), " dans la section Mes réservations de votre profil."])},
        "detail4Condiciones1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si le client renonce à l'expérience après la formalisation du dépôt exigé pour considérer la réservation comme NON REMBOURSABLE, il devra payer les montants suivants :"])}
      },
      "realizarCheckin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effectuer le checkin"])},
      "Thanks": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous vous contacterons dans moins de 48 heures !"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci"])},
        "title-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci pour les informations. Vous aurez bientôt des nouvelles"])}
      },
      "tripInformation": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caractéristiques du Voyage"])},
        "whereFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["D'où voyagez-vous?"])},
        "howMany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Combien de personnes êtes-vous?"])},
        "whoTravel": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avec qui voyagez-vous?"])},
          "friends": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amis"])},
          "family": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Famille"])},
          "couple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Couple"])},
          "work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collègues de travail"])}
        },
        "places": {
          "andorra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andorre"])},
          "formigal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formigal"])},
          "sierraNevada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sierra Nevada"])},
          "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre lieu"])},
          "noPreference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de préférence"])}
        },
        "fields": {
          "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaires"])},
          "otherStation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre station"])},
          "others": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres"])},
          "writeHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Écrivez ici"])},
          "otherInterest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre intérêt"])},
          "otherCompanion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre accompagnant"])}
        },
        "interests": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qu'est-ce qui vous intéresse le plus?"])},
          "cousine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gastronomie"])},
          "party": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fête et ambiance"])},
          "relax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relaxation et spa"])},
          "snow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités de neige"])},
          "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres intérêts"])}
        },
        "distance": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance des pistes"])},
          "near": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proche des pistes"])},
          "noPreference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de préférence"])}
        },
        "whenTravel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dates du voyage"])},
        "howManyDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Combien de jours voulez-vous skier?"])},
        "destiny": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Où voulez-vous aller?"])},
          "noPreference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de préférence, conseillez-moi!"])}
        },
        "accommodation": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Où préférez-vous séjourner?"])},
          "hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hôtel"])},
          "apartment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appartement"])},
          "noPreference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je n'ai pas de préférence"])}
        },
        "tracks": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance des pistes?"])},
          "near": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je veux être proche des pistes"])},
          "noPreference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peu importe si je dois me déplacer"])}
        }
      }
    }
  },
  "sponsors": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sponsors et partenaires"])}
  },
  "footer": {
    "us": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qui sommes-nous ?"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
      "work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travailler avec nous"])},
      "blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog"])},
      "faqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQs"])}
    },
    "saleGroups": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offres pour les groupes"])},
      "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voyages scolaires"])},
      "family": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voyages en famille"])},
      "university": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voyages universitaires"])},
      "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voyages d'entreprise"])},
      "premium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voyages premium"])}
    },
    "saleSki": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offres de ski"])},
      "december": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ski Pont de Décembre"])},
      "christmas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ski de Noël"])},
      "newYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ski du Nouvel An"])},
      "holyWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ski pendant la Semaine Sainte"])},
      "others": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres offres"])}
    },
    "terminosCondiciones": {
      "bono": {
        "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditions Légales de Vente de Services par TRAVEL&NIGHT S.L. - NIF B87814125 L'achat de ce billet implique l'acceptation des conditions légales, de la politique de confidentialité et des cookies, que vous pouvez consulter sur le site d'achat. Ce bon peut être révoqué à tout moment par l'organisateur en proposant le remboursement du montant payé. L'organisation établit ses propres conditions légales pour l'accès et la vente de services, que vous devez consulter sur leurs médias ou demander explicitement à ceux-ci. Pour toute réclamation relative à l'organisation de l'événement ou de l'expérience, le détenteur de ce bon doit s'adresser à l'organisateur. Aucun changement ou remboursement n'est accepté, sauf ceux autorisés par l'organisateur. L'organisation se réserve le droit d'admission et peut refuser ou expulser le bénéfice de l'un des services faisant partie de l'expérience. TRAVEL&NIGHT n'est pas responsable des problèmes résultant de la contrefaçon, de la duplication ou de la vente illégale de ce billet. En cas de remboursements, l'organisateur se réserve le droit de ne pas retourner les frais de gestion car ceux-ci ont été correctement effectués par la plateforme."])}
      }
    },
    "copyright": {
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juan Hurtado de Mendoza, 17 posterior, 1º Dcha, 28036 - Madrid, Espagne"])},
      "legal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentions légales"])},
      "cookies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politique de cookies"])},
      "faqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
      "conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditions générales"])}
    },
    "social": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivez-nous !"])}
  },
  "cookies": {
    "aceptar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J'accepte tous les cookies"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous utilisons des cookies pour améliorer nos services, analyser et personnaliser votre navigation. Pour continuer à naviguer sur notre site Web, vous devez accepter leur utilisation. Vous pouvez modifier les paramètres ou obtenir plus d'informations dans notre"])},
    "extras": {
      "analiticas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyses"])},
      "marketing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketing"])},
      "necesarias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nécessaires"])}
    },
    "gestionar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérer les cookies"])},
    "politicas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politique en matière de cookies"])},
    "preguntas": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QU'EST-CE QUE LES COOKIES ?"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AUTORISATION POUR L'UTILISATION DES COOKIES ?"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TYPES DE COOKIES UTILISÉES ?"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COMMENT DÉSACTIVER OU SUPPRIMER LES COOKIES ?"])}
    },
    "respuestas": {
      "1": {
        "bloque1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les cookies sont des fichiers texte stockés sur l'appareil de l'utilisateur qui navigue sur Internet et qui, en particulier, contiennent un numéro permettant d'identifier de manière unique ledit appareil, même s'il change d'emplacement ou d'adresse IP."])},
        "bloque2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les cookies sont installés pendant la navigation sur Internet, soit par les sites Web que l'utilisateur visite, soit par des tiers avec lesquels le site Web est en relation, et permettent à ce dernier de connaître son activité sur le même site ou sur d'autres avec lesquels il est en relation, par exemple : le lieu d'accès, la durée de connexion, l'appareil à partir duquel il se connecte (fixe ou mobile), le système d'exploitation et le navigateur utilisés, les pages les plus visitées, le nombre de clics effectués et des données sur le comportement de l'utilisateur sur Internet."])},
        "bloque3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les cookies sont associés uniquement à votre navigateur et ne fournissent pas par eux-mêmes de données personnelles. Les cookies ne peuvent pas endommager votre appareil et sont également très utiles, car ils nous aident à identifier et à résoudre les erreurs. Le site Web est accessible sans que les cookies soient activés, bien que leur désactivation puisse empêcher son bon fonctionnement."])}
      },
      "2": {
        "bloque1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conformément à l'avis sur les cookies qui apparaît en bas de page du site Web, l'utilisateur ou visiteur des mêmes accepte qu'en naviguant sur celui-ci, il consent expressément à l'utilisation de cookies selon la description détaillée ci-dessous, sauf dans la mesure où il aurait modifié la configuration de son navigateur pour refuser leur utilisation."])}
      },
      "3": {
        "bloque1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le propriétaire du site Web utilise des cookies strictement nécessaires pour faciliter une navigation correcte sur le site Web, ainsi que pour assurer que son contenu est chargé efficacement. Ces cookies sont également des cookies de session, c'est-à-dire qu'ils ont un caractère temporaire et expirent et sont automatiquement effacés lorsque l'utilisateur ferme son navigateur."])},
        "bloque2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookies de Facebook Ireland Lt, utilisés pour permettre aux visiteurs d'interagir avec le contenu de Facebook et générés uniquement pour les utilisateurs de ces réseaux sociaux. Les conditions d'utilisation de ces cookies et les informations collectées sont régies par la politique de confidentialité de la plateforme sociale correspondante."])}
      },
      "4": {
        "bloque1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer et gérer les cookies sur Internet Explorer"])},
        "bloque2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effacer, activer et gérer les cookies sur Chrome"])},
        "bloque3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer les cookies sur Firefox"])},
        "bloque4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration Web de Safari sur l'iPhone, l'iPad et l'iPod touch"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les cookies peuvent être désactivés et supprimés en suivant les guides suivants en fonction du navigateur utilisé :"])}
      }
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["POLITIQUE CONCERNANT LES COOKIES"])}
  },
  "us": {
    "whoWeAre": {
      "banner": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À PROPOS DE NOUS"])},
        "text": {
          "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous sommes une plateforme numérique qui révolutionne le secteur du ski en concevant des expériences dans la neige, que ce soit en individuel ou en groupe. Depuis nos débuts, nous avons accompagné plus de 30 000 voyageurs dans toutes les stations d'Espagne, d'Andorre et de France."])},
          "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notre spécialité est de connecter les voyageurs et de créer des communautés à travers les loisirs et le sport dans la neige. Nous disposons des meilleurs après-ski et organisons notre propre festival dans la neige, le Winter Snow Festival en Andorre. De plus, nous proposons des voyages sur mesure pour les familles, les entreprises, les écoles et les gourmets, nous adaptant aux besoins et aux goûts de nos voyageurs, en leur offrant la meilleure offre de détente, de restauration et de loisirs."])}
        }
      },
      "team": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ÉQUIPE SKI&NIGHT"])}
      },
      "values": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VALEURS DE SKI&NIGHT"])},
        "enviroment": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ambiance jeune et conviviale"])},
          "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chez Ski&Night, nous sommes une entreprise jeune, composée d'une équipe proche et flexible, ce qui nous permet de bien connaître le produit que nous vendons."])},
          "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La bonne humeur se transmet lors de la création d'expériences dans la neige et dans notre capacité à nous adapter aux besoins de nos voyageurs, que ce soit pour les universitaires, les écoles, les entreprises, les gourmets ou les familles."])}
        },
        "honesty": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Honnêteté et transparence"])},
          "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'honnêteté est l'une des clés de notre entreprise, étant notre principe de base dans nos relations avec nos voyageurs et nos fournisseurs."])},
          "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depuis nos débuts, plus de 25 000 voyageurs nous ont fait confiance, et un pourcentage élevé renouvelle son expérience avec S&N, avec un taux de fidélité atteignant 83,8%."])}
        },
        "compromise": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engagement et sentiment d'appartenance"])},
          "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La marque Ski&Night s'est imposée comme un style de leadership et d'engagement, une communauté qui a évolué depuis ses débuts pour devenir une marque leader dans le secteur de la neige."])},
          "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un engagement qui s'étend non seulement à la relation avec les voyageurs, mais aussi aux fournisseurs, aux stations et à l'environnement naturel dans lequel nous exerçons notre activité."])}
        },
        "figures": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GRANDES CHIFFRES MONDIAUX"])},
          "experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANNÉES D'EXPÉRIENCE"])},
          "travelers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VOYAGEURS"])},
          "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VOYAGES EN GROUPE"])},
          "collaborations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COLLABORATIONS"])}
        },
        "season": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RÉSUMÉ SAISON 2021/22"])}
      }
    }
  },
  "experencia": {
    "colegios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si tienes entre 5 y 17 años y quieres aprovechar al máximo los días de esquí, este es tu experencia. Mejora tu técnica y disfruta de la naturaleza, nosotros te facilitamos el transporte, alojamiento y monitores."])},
    "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este es tu experencia si quieres coincidir con gente de tu edad en los mejores festivales y après-skis. ¿Te atreves a vivir una experiencia inolvidable en la nieve?"])},
    "familias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Te ofrecemos el alojamiento más cómodo para viajar a la nieve con tu familia. Además, podrás contratar clases de esquí para niños y muchas otras actividades adaptadas a tus necesidades."])},
    "IntroduceCodViaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduce el código de tu experencia"])},
    "trabajadores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viajar con tus compañeros de trabajo es muy fácil. Si tienes más de 25 años, únete a nuestras experiencias organizados para coincidir con gente como tú, en las que el esquí y el ocio nocturno te sorprenderán."])}
  },
  "porqueReservar": [
    {
      "icono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confort de paiement"])},
      "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décidez si vous préférez payer le voyage en totalité ou seulement votre part, et consultez l'état dans votre profil."])}
    },
    {
      "icono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service personnalisé"])},
      "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notre personnel de service client et nos guides sur place répondront à toutes vos questions."])}
    },
    {
      "icono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flexibilité dans vos voyages"])},
      "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez les extras et les services dont vous avez besoin et personnalisez votre voyage au maximum."])}
    },
    {
      "icono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loisirs à votre mesure"])},
      "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profitez d'une large offre de détente, d'activités dans la neige, de gastronomie et de vie nocturne."])}
    }
  ],
  "viajesCatalogo": {
    "availableTrips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["voyages disponibles"])},
    "tituloFiltroPoblaciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Villes"])},
    "placeholderSelectMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez un mois"])},
    "tituloFiltroLugarExperiencia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieu de l'expérience"])},
    "tituloFiltroPrecios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix"])},
    "tituloFiltroAgrupaciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupes"])},
    "tituloFiltroCategorias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégories"])},
    "filtroPoblaciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez les villes que vous souhaitez visiter"])},
    "filtroPrecios": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Choisissez la fourchette de prix (", _interpolate(_named("currency")), ") dans laquelle vous souhaitez rechercher"])},
    "filtrosFestivales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez les festivals auxquels vous souhaitez participer"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejoignez l'un de ces voyages en groupe et profitez de l'expérience avec vos amis. Vous pouvez réserver une nouvelle chambre ou rejoindre une chambre existante"])},
    "descriptionPremium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réservez l'un de nos voyages exclusifs à la neige avec des expériences de détente, gastronomiques, culturelles ou extrêmes. Vous pouvez également nous contacter directement au <a href='https://wa.me/+34910054547' target='_blank'><span class='mx-2 text-primary'> +34 910054670 </span></a> et nous concevrons un voyage entièrement adapté à vos besoins."])}
  },
  "seleccionAlojamientos": {
    "1availableTrips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hébergement disponible"])},
    "availableTrips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hébergements disponibles"])},
    "descriptionTrips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour votre expérience en groupe, nous avons ces hébergements disponibles"])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir les emplacements"])},
    "places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hébergements"])},
    "1places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hébergement"])},
    "ordenarPor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trier par"])},
    "ordenarMenorMayor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trier par prix croissant"])},
    "ordenarMayorMenor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trier par prix décroissant"])}
  },
  "mejoresOfertas": {
    "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les meilleures offres"])}
  },
  "porqueReservaTitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pourquoi réserver chez Ski and Night ?"])},
  "porqueReservaTitulo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pourquoi réserver chez Sea and Night ?"])},
  "habitacionBuscador": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aidez-nous à trouver le meilleur prix, organisez vos chambres"])},
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez le nombre de chambres/appartements"])},
    "habitacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chambre"])},
    "adulto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adultes"])},
    "ninos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enfants"])},
    "nino": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enfant"])},
    "edadNino": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Âge des enfants"])}
  },
  "viajesGrupos": {
    "todosPublicos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les publics"])},
    "mas25anios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de 25 ans"])},
    "de18a25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De 18 à 25 ans"])},
    "de0a17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De 0 à 17 ans"])},
    "msjPlazasLibres": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["places libres dans la chambre, en cas de rester libres un supplément sera assumé de"])},
    "msjRepartir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pour chaque place libre à répartir entre les membres de la chambre au titre de 'Reste de l'hébergement'"])},
    "modificarReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "plazaLibre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place libre"])},
    "invitarCorreo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez inviter par email en cliquant sur le bouton."])},
    "llenarInformacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remplissez les informations de Parlez-nous un peu plus de vous."])},
    "faltaPoliticasPrivacidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les politiques de confidentialité et la politique d'hébergement en occupation complète doivent être confirmées."])},
    "faltaPoliticasCancelacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les politiques d'annulation doivent être confirmées."])},
    "faltaRespuestasFormulario": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les questions du formulaire doivent être répondues."])},
    "infoSeleccionServicios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorsque vous aurez terminé votre réservation, envoyez le lien que nous vous fournirons aux autres personnes afin qu'elles puissent participer à votre expérience. "])}
  },
  "reserva": {
    "misReservas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes réservations"])},
    "reactivarParaRecuperla": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez sur le bouton pour la réactiver"])},
    "proximasReservas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos prochaines réservations"])},
    "misQRs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes QRs"])},
    "proximasNights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes prochains billets"])},
    "mensajeCompra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorsque vous ferez un achat, il apparaîtra ici"])},
    "estadoPagoReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["État du paiement de la réservation"])},
    "informacionExperiencia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information de l'expérience"])},
    "personasHabitacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnes dans la chambre"])},
    "resumen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résumé"])},
    "tabs": {
      "personas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnes dans la chambre"])},
      "resumen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résumé"])},
      "informacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information de l'expérience"])},
      "estadoPago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["État du paiement"])},
      "comunidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Communauté"])}
    },
    "comunidad": {
      "invitacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitation à une communauté"])},
      "unirse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejoindre la communauté"])},
      "descripcionInvitacion": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous avez été invité à rejoindre une communauté dans l'expérience ", _interpolate(_named("experiencia")), ". Sélectionnez avec laquelle de vos réservations vous souhaitez rejoindre."])},
      "tusReservas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos réservations dans cette expérience"])},
      "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créez votre communauté ou rejoignez-en une existante et permettez de regrouper votre réservation avec d'autres. La communauté permet à l'organisateur de savoir avec qui vous voyagez et d'en tenir compte pour les transports, les hébergements et les autres services de l'expérience. Les communautés sont privées, pour rejoindre l'une d'elles, vous devez demander le lien à son créateur."])},
      "creacionExitosa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Communauté créée avec succès"])},
      "boton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une communauté"])},
      "yaEnSupergrupo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous appartenez déjà à ce super-groupe"])},
      "aunSinReservas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas encore de réservations"])},
      "pincha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez"])},
      "aqui": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ici"])},
      "paraComenzarReservar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pour commencer à réserver"])},
      "miembrosComunidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membres de la communauté"])},
      "unidoExito": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez correctement rejoint la communauté"])},
      "seleccionaGrupo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez un groupe pour rejoindre"])},
      "crear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une communauté"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de votre communauté"])},
      "pertenece": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre réservation appartient à la communauté suivante :"])},
      "compartirLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partagez le lien pour que d'autres personnes rejoignent directement votre communauté :"])},
      "miembros": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membres"])},
      "miembro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membre"])},
      "plazaLibre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["places libres"])},
      "confirmarEliminar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir supprimer cette communauté ?"])},
      "noRecuperar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une fois supprimée, elle ne pourra pas être récupérée."])},
      "errorEliminar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur lors de la suppression de la communauté"])},
      "errorCrear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur lors de la création de la communauté"])},
      "eliminacionExitosa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Communauté supprimée avec succès"])}
    },
    "pendientePago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente de paiement"])},
    "totalReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total de la réservation"])},
    "reservaEncuentra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette réservation se trouve"])},
    "miViaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon voyage"])},
    "verQRs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir les QR"])},
    "reservasCancelar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez les réservations que vous souhaitez annuler"])},
    "mensajeCompraExp": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cliquez ", _interpolate(_named("aqui")), " pour voir toutes les expériences disponibles"])},
    "pago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement"])},
    "paga": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paye"])},
    "totalPagado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total payé"])},
    "totalPendiente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total restant"])},
    "saldoPendiente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solde"])},
    "graciasReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci d'avoir réservé"])},
    "noReservas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas encore de réservations"])},
    "noFiestas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas encore de réservations de fêtes"])},
    "moreAbout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parlez-nous de vous"])},
    "howPay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez comment vous souhaitez payer"])},
    "complementos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compléments"])},
    "suplementos": {
      "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suppléments et dépenses"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suppléments et dépenses"])},
      "PLAZAS_LIBRES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Places libres"])}
    },
    "tasas": {
      "externas": {
        "TASA_TURISTICA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxe de séjour"])}
      },
      "internas": {
        "TASA_GESTION_PLATAFORMA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxe de gestion de la plateforme"])},
        "TASA_GESTION_CAMBIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxe de gestion du changement"])}
      }
    },
    "tasa": {
      "externas": {
        "turistica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxe touristique"])}
      },
      "gestion-plataforma": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais de gestion de la plateforme"])},
      "gestion-cambio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais de gestion de changement"])}
    },
    "pagos": {
      "pago": {
        "senial": {
          "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acompte"])}
        },
        "total": {
          "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement total de l'expérience"])}
        },
        "viaje": {
          "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement partiel"])},
          "diferido": {
            "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement différé"])}
          }
        }
      }
    },
    "tipo": {
      "PC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pension complète"])},
      "MP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demi-pension"])},
      "AD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chambre petit-déjeuner"])},
      "SA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chambre seule"])},
      "SD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Petit-déjeuner seul"])}
    },
    "superGrupo": {
      "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Super-Groupe"])},
      "informacionTransporte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations sur le transport"])},
      "informacionViaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations sur votre voyage"])},
      "fiestaContradas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fêtes réservées"])},
      "crear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un super-groupe"])},
      "unirse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejoindre un super-groupe"])},
      "msjNoGrupoamigo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre groupe n'a toujours aucun groupe ami. Créez un super-groupe ou rejoignez-en un existant avant la date de commande pour voyager dans le même bus et séjourner dans des chambres aussi proches que possible."])},
      "msjSuperGruposEdificio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les super-groupes ne seront pris en compte que pour les bâtiments proches en raison du point d'arrivée"])},
      "pertenceSuperGrupo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous appartenez au super-groupe"])},
      "msjPuntoLlegado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les super-groupes ne seront pris en compte que pour les bâtiments proches en raison du point d'arrivée"])}
    },
    "plazasLibres": {
      "modal": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il reste encore des places disponibles dans votre réservation !"])},
        "tituloSingular": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Il reste encore ", _interpolate(_named("plazasLibres")), " place libre dans votre réservation !"])},
        "tituloPlural": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Il reste encore ", _interpolate(_named("plazasLibres")), " places libres dans votre réservation !"])},
        "bodySingular": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Il y a de la place pour ", _interpolate(_list(0)), " personne de plus dans votre réservation. Un supplément de ", _interpolate(_list(1)), " sera ajouté à chaque personne confirmée. Si les places libres sont finalement occupées, le supplément sera remboursé."])},
        "bodyPlural": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Il y a de la place pour ", _interpolate(_list(0)), " personnes de plus dans votre réservation. Un supplément de ", _interpolate(_list(1)), " sera ajouté à chaque personne confirmée. Si les places libres sont finalement occupées, le supplément sera remboursé."])},
        "addSuplemento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un supplément à la réservation"])},
        "genteReservar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il manque des gens à la réservation"])},
        "completarPlazas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remplir les places"])}
      }
    },
    "grupo": {
      "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe"])},
      "msjGrupo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupez votre réservation avec d'autres. Les groupes seront pris en compte pour les services de l'expérience, par exemple lors de la distribution des transports. Pour créer un groupe, envoyez le lien aux utilisateurs des autres réservations."])},
      "miembrosGrupo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membres du groupe"])}
    },
    "buses": {
      "nombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autobus"])},
      "horaSalidaIda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heure de départ (aller)"])},
      "lugarSalidaIda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieu de départ (aller)"])},
      "horaSalidaVuelta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heure de départ (retour)"])},
      "lugarSalidaVuelta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieu de départ (retour)"])}
    },
    "bono": {
      "descargarBono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger le bon"])},
      "fechaIni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de début"])},
      "fechaFin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de fin"])},
      "direccion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
      "informacionGeneral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations générales"])},
      "checkIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check In"])},
      "checkOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check Out"])},
      "regimen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Régime"])},
      "impuestosIncluidos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxes incluses"])},
      "incluidoPaqueteBasico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inclus dans le forfait de base"])},
      "servicioExtra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service supplémentaire"])},
      "localizador": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Localisateur"])},
      "titular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titulaire"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email de contact"])}
    },
    "checkIn": {
      "msg": {
        "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrement et données personnelles complétés pour toutes les réservations."])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est obligatoire de remplir toutes les informations."])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s'est produite lors du traitement des réservations."])}
      },
      "done": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Check In de ", _interpolate(_named("nombre")), " terminé"])},
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrement"])},
      "alergias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allergies"])},
      "nacionalidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nationalité"])},
      "fechaNacimiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de naissance"])},
      "lugarNacimiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieu de naissance"])},
      "dni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carte d'identité/Passeport"])},
      "soporteDni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de support DNI"])},
      "fechaCaducidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'expiration"])},
      "fechaExpedicion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de délivrance"])},
      "pais": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])},
      "direccion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
      "codigoPostal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code postal"])},
      "altura": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taille"])},
      "tallaPie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pointure"])},
      "peso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poids"])},
      "card": {
        "sinCompletar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce formulaire n'est pas complet"])},
        "sinGurdar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce formulaire n'est pas enregistré"])},
        "guardado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce formulaire a déjà été envoyé"])},
        "completar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compléter"])},
        "verRespuestas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir les réponses"])},
        "enviar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])},
        "rellena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remplissez le formulaire"])},
        "rellenaLos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remplissez les formulaires"])}
      },
      "error": {
        "rellenar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il existe des formulaires incomplets"])},
        "formularioIncompleto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le formulaire n'est pas complet"])},
        "errorEnvio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur lors de l'envoi du formulaire"])}
      },
      "sexo": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sexe"])},
        "hombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Homme"])},
        "mujer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Femme"])}
      },
      "llegada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrivée"])},
      "salida": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Départ"])},
      "anversoDni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recto de la carte d'identité/passeport"])},
      "reversoDni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verso de la carte d'identité/passeport"])},
      "seleccionarDocumento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner un document"])},
      "placeholders": {
        "seleccionaNacionalidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez une nationalité"])},
        "seleccionaPais": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez un pays"])},
        "escribeTuRespuesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Écrivez votre réponse"])},
        "fecha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jj/mm/aa"])},
        "comerciales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l'agent commercial"])}
      },
      "paises": {
        "españa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espagne"])},
        "otro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre"])}
      }
    },
    "informacionViaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["plus d'informations sur l'hébergement"])},
    "msjFechaLimite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La date limite pour effectuer tout paiement ou modification de services gratuitement sera avant le jour"])},
    "msjPagoRealizado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le paiement de votre voyage a déjà été effectué. Soyez attentif au cas où des fêtes seraient publiées."])},
    "msjNoPagos": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Il vous reste ", _interpolate(_named("pendientePago")), _interpolate(_named("currency")), " à payer."])},
    "reservasDe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réservation/s de"])},
    "mantenerReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maintenir la réservation"])},
    "msjSolucionReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous pouvons vous aider à trouver une solution alternative si vous devez apporter des modifications à votre réservation"])},
    "proximosViajes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expériences à venir :"])},
    "condicionesReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les conditions de réservation pour le voyage sont les suivantes"])},
    "concepto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concept"])},
    "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "precio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix"])},
    "recuperarReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récupérer la réservation"])},
    "codViaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C'est le code de votre voyage"])},
    "mensajeThankYouSingular": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous avez ", _interpolate(_named("plazaslibres")), " place libre, partagez le lien pour les compléter ou un supplément de ", _interpolate(_named("suplemento")), " sera ajouté à chaque personne confirmée."])},
    "mensajeThankYouPlural": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous avez des ", _interpolate(_named("plazaslibres")), " places libres, partagez le lien pour les compléter ou un supplément de ", _interpolate(_named("suplemento")), " sera ajouté à chaque personne confirmée."])},
    "mensajeThankYouCompleto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre réservation comprend les personnes suivantes:"])},
    "mensajeThankYouAnular": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["S'il n'est pas terminé à la ", _interpolate(_named("fechaLimite")), ", il sera automatiquement annulé."])},
    "mensajeFechaLimite": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Délai de paiement: ", _interpolate(_named("fechaLimite")), "."])},
    "repartirCoste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si le nombre de places n'est pas atteint, un supplément sera ajouté pour chaque personne confirmée."])},
    "mensajeExperiencia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous sommes impatients de vous voir et de profiter de l'expérience ensemble."])},
    "msjAnadirExtras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez ajouter quelques extras si vous les avez oubliés"])},
    "anadirExtras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter des extras"])},
    "masExtras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus d'extras"])},
    "extras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extras"])},
    "serviciosContrados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services contractés"])},
    "realizarPago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effectuer le paiement"])},
    "cambioHabitacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changement de chambre"])},
    "informesTotales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapports totaux"])},
    "cancelacionEntre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["si l'annulation se produit entre le"])},
    "costesAsociadosReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les coûts associés à l'annulation de la réservation sont les suivants :"])},
    "msjReservaDescartada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La réservation a été abandonnée"])},
    "msjReservaActiva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez déjà une réservation pour ce voyage, choisissez un autre voyage ou consultez vos réservations"])},
    "descuento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réduction"])},
    "descuentoCod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code de réduction"])},
    "descuentoCodIntrod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez-le ici"])},
    "descuentoCodError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le code de réduction '", _interpolate(_named("codigo")), "'. Ce n'est pas valable."])},
    "paqueteBasico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forfait de base"])},
    "suplementosGastos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suppléments et dépenses"])},
    "msjPendientePagoReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez une réservation en attente de paiement pour ce voyage. Souhaitez-vous l'annuler ou continuer avec celle-ci ?"])},
    "revisaDatos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifiez les données de votre réservation"])},
    "pagarTotalReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payer le montant total de la réservation"])},
    "enlaceUnirse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A la fin de la réservation, envoyez le lien que nous fournirons au reste des personnes afin qu'elles puissent rejoindre"])},
    "recuerdaPagar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N'oubliez pas que vous pouvez payer la réservation en totalité ou seulement une partie"])},
    "pagarAhora": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payez maintenant"])},
    "abonarAntesDe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["et payez le montant restant avant"])},
    "pagarPlazos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payez en plusieurs fois."])},
    "reservaCancelada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réservation annulée"])},
    "msjCheckInReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour accélérer le processus de check-in à votre arrivée, veuillez remplir vos informations. Vous pouvez remplir vos propres données ou celles de votre chambre/appartement."])},
    "msjDatosReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez consulter les données de réservation des personnes pour lesquelles vous avez effectué le premier paiement"])},
    "disponeCodReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous disposez d'un code de réservation"])},
    "msjFiesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelqu'un a parlé de fête"])},
    "msjNoFiesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il n'y a pas encore de fêtes disponibles pour aucun de vos voyages. Dès qu'elles seront confirmées, vous serez notifié par e-mail pour que vous puissiez réserver votre place."])},
    "msjAmigoReservado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous avez un ami qui a déjà réservé et que vous souhaitez vous joindre à sa chambre, cliquez sur le bouton ci-dessous"])},
    "gastos": {
      "gasto": {
        "tasa-turistica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais de taxe de séjour"])},
        "gestion": {
          "extras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais supplémentaires de gestion"])}
        }
      },
      "FEE_PLATAFORMA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais de gestion de la plateforme"])},
      "incremento": {
        "camas": {
          "vacias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais d'augmentation pour les lits vides"])}
        }
      },
      "recargo": {
        "pago": {
          "tardio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais de paiement tardif"])}
        }
      },
      "complemento": {
        "plazas": {
          "libres": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supplément pour places libres"])}
        }
      }
    },
    "visualizacionOcupantes": {
      "persona": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personne"])},
      "plaza": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place"])},
      "libre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["disponible"])}
    }
  },
  "rooming": {
    "miRooming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon Rooming"])},
    "resena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laissez-nous un avis"])},
    "siguenos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivez-nous"])},
    "gestorRooming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestionnaire de Rooming"])},
    "tooltipAnadirIntegrantes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une fois que vous avez cliqué ici, sélectionnez les membres dans le tableau à droite"])},
    "organizarDistribucion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organiser la Distribution"])},
    "tooltipMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez la chambre que vous souhaitez distribuer puis les personnes que vous souhaitez mettre dans cette chambre à partir du tableau de gauche"])},
    "cardHabitacion": {
      "anadirIntegrantes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+ Ajouter des membres"])},
      "selectFromTable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez les membres dans le tableau"])}
    },
    "estado": {
      "NUEVO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau"])},
      "EN_EDICION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En modification"])},
      "PUBLICADO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publié"])}
    },
    "tabla": {
      "nombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
      "apellidos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
      "alojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hébergement"])},
      "sinRepartir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non répartis"])},
      "repartidos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répartis"])},
      "buscarNombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher un nom"])},
      "guardar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder"])},
      "descartar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
      "aceptar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepter"])}
    },
    "enviar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])},
    "hasTerminado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avez-vous terminé?"])},
    "guardarSalir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer et quitter"])},
    "guardarSalirMensaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous n'avez pas terminé la distribution et souhaitez continuer plus tard, cliquez sur 'Enregistrer et quitter'. Si vous avez terminé, cliquez sur 'Envoyer', notez qu'une fois envoyé, aucun changement ne pourra être apporté."])},
    "error": {
      "publicar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur lors de la publication de votre rooming."])},
      "guardar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur lors de l'enregistrement de votre voyage."])}
    }
  },
  "alojamiento": {
    "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hébergement"])},
    "mejoresOfertas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meilleures offres"])},
    "nuestrasExperiencias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nos expériences"])},
    "verTodasExperiencias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir tout"])},
    "imagenes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Images"])},
    "verImagenes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir les images"])},
    "eligeDistancia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez la distance aux pistes (m)"])},
    "eligeDistanciaPokerRed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez la distance au casino (m)"])},
    "eligeTipo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez le type d'hébergement"])},
    "eligePrecio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez la gamme de prix de votre voyage"])},
    "galeria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Galerie"])},
    "compartir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partager"])},
    "seleccion_buscador": {
      "desde": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De"])},
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voyages sur mesure"])},
      "descripcion_extras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vous donnons la possibilité de choisir vos extras à votre guise"])}
    },
    "ordenacion": {
      "masEconomicos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le moins cher"])},
      "menosEconomicos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le plus cher"])},
      "mejorValorados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meilleur évalué"])},
      "menosValorados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moins bien évalué"])}
    },
    "habitaciones": {
      "singular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chambre"])},
      "plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chambres"])}
    },
    "tipos": {
      "galeria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Galerie"])},
      "apartamento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appartement"])},
      "hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hôtel"])},
      "albergue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auberge"])},
      "estudio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Studio"])},
      "no aplica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non applicable"])}
    },
    "nombreTitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "filtros": {
      "titulo": {
        "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrer les hébergements"])},
        "reestablecer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser les filtres"])}
      },
      "tipos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez le type d'hébergement"])},
      "poblaciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez la population à laquelle vous aimeriez aller"])},
      "precios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez la gamme de prix de votre voyage"])},
      "distancia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez la distance aux pistes (m)"])}
    },
    "resultados": {
      "encontradoUno": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " hébergement trouvé"])},
      "encontradosVarios": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " hébergements trouvés"])},
      "encontradosMuchos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de 50 voyages disponibles"])},
      "sinResultados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun résultat trouvé avec votre recherche, essayez une nouvelle recherche."])},
      "sinResultadosFechas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous n'avons pas d'hébergements disponibles pour les dates sélectionnées. Nous vous recommandons de refaire une nouvelle recherche. Vous pouvez consulter la disponibilité dans nos voyages, et si vous ne trouvez rien, écrivez-nous pour des recommandations."])}
    },
    "nuestrosViajes": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nos voyages"])},
      "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejoignez nos voyages avec vos amis et rencontrez une communauté de voyageurs comme vous. Qu'attendez-vous pour rejoindre les meilleures expériences de neige ?"])},
      "coincidencia": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre recherche correspond aux dates de "])},
        "verCoincidencia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir la correspondance"])},
        "seguirBuscando": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer la recherche"])}
      },
      "queEs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Que sont nos voyages ?"])}
    },
    "filtro": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtre voyages"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrer les hébergements"])},
      "reestablecer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser les filtres"])}
    },
    "buscador": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche de voyages"])}
    },
    "porPersona": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["par personne"])},
    "valoracion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluation"])},
    "masInformacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus d'informations sur l'hébergement"])},
    "diasForfait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jours de forfait du"])},
    "estrellas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étoiles"])},
    "distancia": {
      "a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À"])},
      "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de"])}
    },
    "precio": {
      "desde": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À partir de"])},
      "porPersona": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["par personne"])}
    },
    "modales": {
      "nuestrosViajes": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nos voyages"])},
        "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejoignez nos voyages avec vos amis et rencontrez une communauté de voyageurs comme vous. Qu'attendez-vous pour rejoindre les meilleures expériences de neige ?"])},
        "coincidencia": {
          "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre recherche correspond aux dates de l'un de nos voyages"])},
          "verCoincidencia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir la correspondance"])},
          "seguirBuscando": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer la recherche"])}
        }
      },
      "filtros": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtre voyages"])},
        "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrer les hébergements"])},
        "reestablecer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser les filtres"])},
        "tipos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez le type d'hébergement"])},
        "precios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez la gamme de prix de votre voyage"])},
        "distancia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez la distance aux pistes (m)"])}
      },
      "buscador": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche de voyages"])}
      }
    },
    "sinResultados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun résultat trouvé avec votre recherche, essayez une nouvelle recherche."])},
    "sinResultadosFechas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous n'avons pas d'hébergements disponibles pour les dates sélectionnées. Nous vous recommandons de refaire une nouvelle recherche. Vous pouvez consulter la disponibilité dans nos voyages, et si vous ne trouvez rien, écrivez-nous pour des recommandations."])},
    "forfait": {
      "dias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jours de forfait du"])},
      "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en"])},
      "al": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["au"])},
      "distancia": {
        "a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À"])},
        "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de la station de ski"])}
      }
    },
    "lineUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programmation confirmée"])},
    "compartirLanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regardez le voyage que j'ai trouvé !"])},
    "consigueEntrada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtenez votre billet"])},
    "verMas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MONTRER PLUS >"])},
    "verMenos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MONTRER MOINS >"])},
    "consigueAbono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtenez votre pass"])},
    "todasExperiencias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir toutes les expériences"])},
    "proximamente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À venir..."])},
    "verFoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir toutes les photos"])},
    "verMapa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir la carte"])},
    "verMapaAlojamientos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VOIR CARTE HÉBERGEMENTS"])},
    "verUbicaciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir les emplacements"])},
    "servicios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
    "nombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l'hébergement"])},
    "ofertas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offres"])},
    "preguntasFrecuentes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
    "horariosCondiciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horaires et conditions spéciales"])},
    "checkIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-in"])},
    "checkOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-out"])},
    "distancias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distances"])},
    "accesoPistas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accès aux pistes"])},
    "accesoPokerRed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accès au casino"])},
    "puntoInteres": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Points d'intérêt"])},
    "descripcionAlojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description de l'hébergement"])},
    "regimenDisponibles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Régimes disponibles et types de chambre"])},
    "deEstacionEsqui": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de la station de ski"])},
    "cancelacionGratis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulation GRATUITE"])},
    "parcialmenteReembolsable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partiellement remboursable"])},
    "otrasOpcionesViaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres options d'expérience"])},
    "servicioHotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services de l'hôtel"])},
    "ubicacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emplacement"])},
    "fechaAlojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'hébergement"])},
    "filtrosAlojamientos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtre Hébergement"])}
  },
  "validaciones": {
    "pagador": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payeur: "])},
    "validadaError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ERREUR : BILLET DÉJÀ VALIDÉ"])},
    "validadaExito": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BILLET VALIDÉ AVEC SUCCÈS"])},
    "validadaFecha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validé le "])},
    "aLas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" à "])},
    "cantidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantité"])},
    "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "unidades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unités"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "preguntaValidar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous valider le billet?"])},
    "validar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valider"])}
  },
  "general": {
    "y": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["et"])},
    "a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["à"])},
    "el": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le"])},
    "aqui": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ici"])},
    "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de"])},
    "teneis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez"])},
    "grupo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe"])},
    "antesDel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["avant le"])},
    "crear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer"])},
    "ahora": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["maintenant"])},
    "checkInOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrement en ligne"])},
    "checkInRealizado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrement réalisé"])},
    "codigoCopiado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le code a été copié"])},
    "pagoPlazosDeshabilitado": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous êtes désormais à jour de votre échéancier de paiement, vous pouvez effectuer le prochain paiement à partir du ", _interpolate(_named("fecha")), "."])},
    "pagoPendiente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement en attente"])},
    "articulosRelacionados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Articles liés"])},
    "mejoresOfertas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meilleures offres"])},
    "cancelacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulation"])},
    "verMas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir plus"])},
    "verMenos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir moins"])},
    "pagos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiements"])},
    "atras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
    "miPerfil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon profil"])},
    "siguiente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivant"])},
    "ordenar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trier par"])},
    "anios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ans"])},
    "buscar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher"])},
    "opcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Option"])},
    "viaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voyage"])},
    "mostrarMas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher plus"])},
    "viajeAMedida": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expériences sur mesure"])},
    "agotado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Épuisé"])},
    "mostrarMenos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher moins"])},
    "cerrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])},
    "cerrarSesion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnexion"])},
    "solucion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solution"])},
    "para": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pour"])},
    "pagado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payé"])},
    "desde": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["à partir de"])},
    "seleccion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélection"])},
    "terminosCondiciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termes et conditions"])},
    "proteccionDatos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protection des données personnelles"])},
    "porPersona": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["par personne"])},
    "masInformacionViaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus d'informations sur ce voyage"])},
    "masInformacionAlojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus d'informations sur l'hébergement"])},
    "variasOcupaciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diverses professions disponibles"])},
    "eligeAlojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez votre hébergement"])},
    "quieroSaberMas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je veux en savoir plus"])},
    "verTodos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir tout"])},
    "reservarAlojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réserver un logement"])},
    "unirseHabitacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejoindre la chambre"])},
    "servicios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
    "ubicacionesAlojamientos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir carte"])},
    "verUbicaciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir les emplacements"])},
    "bookingLetterButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
    "codigoInvalido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le code n'est pas valide."])},
    "bookingBusDeparture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Départ"])},
    "experienceFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voyager pour"])},
    "dias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jours"])},
    "dia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jour"])},
    "noches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuits"])},
    "noche": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuit"])},
    "del": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["du"])},
    "al": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["au "])},
    "aplicar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appliquer"])},
    "resumen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résumé"])},
    "plaza": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["places"])},
    "cantidadDisponibles": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Plus de ", _interpolate(_named("cantidad")), " disponibles"])},
    "disponibles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["disponibles"])},
    "agotadas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Épuisées"])},
    "reservar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réserver"])},
    "listaEspera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste d'attente"])},
    "listaEsperaSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre demande a été ajoutée à la liste d'attente avec succès"])},
    "listaEsperaError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il n'a pas été possible d'ajouter à la liste d'attente"])},
    "personas": {
      "singular": {
        "adulto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["adulte"])},
        "nino": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enfant"])},
        "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["personne"])}
      },
      "plural": {
        "adulto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["adultes"])},
        "nino": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enfants"])},
        "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["personnes"])}
      },
      "mayus": {
        "adulto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adulte"])},
        "nino": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enfant"])},
        "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personne"])}
      }
    },
    "alojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logement"])},
    "gastos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais"])},
    "habitacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chambre"])},
    "habitaciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chambres"])},
    "regimen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Régime"])},
    "clipboard": {
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL copiée dans le presse-papiers"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur lors de la copie de l'URL"])}
    },
    "esMiReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La réservation est pour moi"])},
    "nombreCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous utiliserons le nom et le prénom de votre profil"])},
    "persona": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personne"])},
    "menorEdad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je suis mineur"])},
    "si": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])},
    "masInformacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus d'informations"])},
    "masInformacionIcon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+ d'informations"])},
    "noInformacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune information disponible"])},
    "borrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effacer"])},
    "esMenorEdad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est mineur"])},
    "fecha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "invalidCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code invalide"])},
    "politicaPrivacidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termes et conditions"])},
    "errorMensaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s'est produite"])},
    "pendiente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente"])},
    "ejecutando": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cours"])}
  },
  "filtroAlojamiento": {
    "borrarFiltros": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effacer les filtres"])},
    "titulo": {
      "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrer l'hébergement"])},
      "poblacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Localités"])},
      "alojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type d'hébergement"])},
      "plaza": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de places"])},
      "reestablecer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser les filtres"])}
    }
  },
  "filtroModalidades": {
    "titulo": {
      "seleccionaGama": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner le niveau"])},
      "seleccionaServicio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner le service"])}
    }
  },
  "selectSubTipos": {
    "titulo": {
      "seguroAnulacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assurance annulation"])},
      "seguroViaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assurance voyage"])}
    }
  },
  "resumenServiciosBuscador": {
    "nochesAlojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jours d'hébergement"])},
    "diasForfait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jours de forfait"])}
  },
  "baseMigas": {
    "seleccionAlojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélection de l'hébergement"])},
    "datosReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réserve"])},
    "confirmacionReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation de la réservation"])},
    "pago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement"])},
    "seleccionFestival": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélection du festival"])}
  },
  "botones": {
    "reservarAlojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réserver un logement"])},
    "recibo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reçu"])},
    "documentacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentation"])},
    "reservaPackFiesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réserver le pack de fête"])},
    "unirseHabitacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejoindre la chambre"])},
    "volverViajes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour aux voyages"])},
    "volverReservas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retourner à mes réservations"])},
    "actualizar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour"])},
    "reservar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réserver"])},
    "cerrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])},
    "verTodos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir tous"])},
    "verDetalles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir les détails"])},
    "verReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir la réservation"])},
    "bonoAdjunto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger le bon joint"])},
    "cancelada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulée"])},
    "descargar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger"])},
    "guardar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
    "volver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revenir"])},
    "entrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrer"])},
    "enviar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])},
    "recuperar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récupérer"])},
    "copiar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copie"])},
    "cancelar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
    "modificar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])},
    "modificarDatos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier les données"])},
    "modificarPagar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier et Payer"])},
    "agregarPersona": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une personne"])},
    "aceptar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepter"])},
    "buscar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher"])},
    "darseBaja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se désabonner"])},
    "aceptarSeleccion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepter la sélection"])},
    "cancelarReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler la réservation"])},
    "modificarReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier la réservation"])},
    "descartar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejeter"])},
    "unirse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejoindre"])},
    "reactivarReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réactiver la réservation"])},
    "recordar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rappeler"])},
    "pagarSenial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payer le signal"])},
    "pagar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payer"])},
    "establecer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Établir"])},
    "resumen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résumé"])},
    "verBono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bon"])},
    "continuar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])},
    "reintentar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réessayer"])}
  },
  "codigoDescuento": {
    "tienesCodDescuento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avez-vous un code de réduction?"])}
  },
  "subtitulo": {
    "seleccionServicios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A la fin de la réservation, envoyez le lien que nous fournirons au reste des personnes afin qu'elles puissent rejoindre."])},
    "nombreHabitacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez le nom de votre chambre"])},
    "comoConociste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment nous avez-vous connus ?"])},
    "comercial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du commercial"])},
    "estudiando": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous en train d'étudier ?"])},
    "escribeRespuesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Écrivez votre réponse"])},
    "eligeUniversidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez une université"])},
    "trabajando": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans quelle entreprise travaillez-vous ?"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "importeTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant total"])},
    "importePendiente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant restant"])},
    "totalReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total de la réservation"])},
    "codigoReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code de réservation"])},
    "codigoViaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code de voyage"])},
    "intoduceAqui": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduisez-le ici"])},
    "codigo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
    "misViajeros": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes voyageurs"])},
    "miPerfil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon profil"])},
    "mailingPublicidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mailing publicitaire"])},
    "subirDni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger la passeport"])},
    "modificarcontrasenia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le mot de passe"])},
    "volverPerfil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour à mon profil"])},
    "datos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Données"])}
  },
  "modalUnirseHabitacion": {
    "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez plus de logement ?"])},
    "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remplissez les informations suivantes et en cas de libération d'une chambre, vous serez averti"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous souhaitez rejoindre la chambre d'un voyage, utilisez le code fourni par la personne ayant effectué la réservation"])}
  },
  "input": {
    "field": {
      "nombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
      "apellido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de famille"])},
      "nombreApellidos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom et prénom"])},
      "correo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse e-mail"])},
      "telefono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone"])},
      "numeroPlazas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de places"])},
      "observaciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remarques"])},
      "redesSociales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réseaux sociaux"])},
      "comerciales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicitaires"])},
      "paqueteBasico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forfait de base"])}
    },
    "placeholder": {
      "nombreHabitacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez le nom de votre chambre"])},
      "introduceCampo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez le champ"])}
    }
  },
  "login": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coordonnées de l'acheteur"])},
    "subtitleRegister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'êtes pas client ? Inscrivez-vous"])},
    "forgetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié"])},
    "recoverPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récupérer le mot de passe"])},
    "questionLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes déjà client ? Connectez-vous"])},
    "questionUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes déjà utilisateur"])},
    "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmez le mot de passe"])},
    "establecerContrasenia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Définir le mot de passe de l'utilisateur"])}
  },
  "errors": {
    "habitacion": {
      "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le code ne correspond à aucune pièce"])}
    },
    "grupoReservas": {
      "pendientes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il y a des réservations à confirmer"])}
    },
    "experiencia": {
      "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'expérience n'admet pas de nouvelles réservations"])}
    },
    "reserva": {
      "alreadyExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'un des voyageurs a déjà réservé pour cette expérience"])}
    },
    "servicios": {
      "cuposInsuficientes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certains des services sélectionnés sont épuisés"])}
    },
    "pago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s'est produite lors du paiement"])},
    "confirmacionPago": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre paiement est en cours de vérification."])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un problème de communication est survenu avec la passerelle de paiement. Cet incident devrait être résolu automatiquement dans quelques heures. Si votre réservation n'apparaît pas dans votre profil sous 24 heures, veuillez nous contacter."])}
    },
    "pagoTransferencia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le paiement de cette réservation se fait par virement bancaire"])},
    "cargaInformacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un problème est survenu lors du chargement des informations. Veuillez réessayer plus tard"])},
    "controlErrorValidarCupo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le voyage demandé n'existe pas"])},
    "noAdmiteReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le voyage n'accepte pas de nouvelle réservation"])},
    "inesperado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur inattendue. Veuillez réessayer plus tard"])},
    "loginReservaDescartar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez vous connecter pour annuler la réservation"])},
    "precioCambiado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un changement de prix a été détecté"])},
    "permisosDescartarReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas les autorisations pour annuler la réservation"])},
    "descartarReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible d'annuler la réservation"])},
    "intentalo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur inattendue. Veuillez réessayer plus tard"])},
    "obligatorios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certains champs obligatoires ne sont pas remplis"])},
    "loginReservaCrear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez vous connecter pour créer une réservation"])},
    "permisosCrearReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas les autorisations pour créer la réservation"])},
    "noInfoReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les informations de la réservation n'ont pas pu être trouvées"])},
    "existeReservaViaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il existe déjà une réservation pour ce voyage"])},
    "emailRegistrado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'e-mail est déjà enregistré"])},
    "permisosProcesos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas les autorisations pour effectuer le processus"])},
    "camposSinCompletar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certains champs ne sont pas remplis"])},
    "recursoNoLocalizado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La ressource demandée n'a pas pu être localisée"])},
    "conflictoEstadoReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il y a un conflit dans l'état de la réservation"])},
    "errorContrasenia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur lors de la saisie du mot de passe"])},
    "loginRequerido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez vous connecter pour continuer"])},
    "reservaNoPendiente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La réservation n'est pas en attente"])},
    "noAlojamientos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun logement trouvé"])},
    "intenteReservaTarde": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez essayer de réserver plus tard. Si l'erreur persiste, contactez-nous."])},
    "generacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur lors de la génération"])},
    "descarga": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur lors du téléchargement"])}
  },
  "servicios": {
    "repercutirFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais de gestion"])},
    "tasaDe": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Frais de ", _interpolate(_named("nombreTasa"))])},
    "forfait": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forfait"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez changer les jours de votre forfait. Sélectionnez les jours que vous souhaitez"])}
    },
    "comida": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repas"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez si vous voulez manger sur les pistes"])}
    },
    "transporte": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transport"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez votre moyen de transport"])}
    },
    "seguro": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assurances"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez votre assurance"])},
      "verPoliza": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir la police"])},
      "subtipo": {
        "cancelacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulation"])},
        "salud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Santé"])}
      }
    },
    "actividad": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez l'activité"])}
    },
    "restauracion": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repas"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez si vous voulez manger sur les pistes"])}
    },
    "festival": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Festivals"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez le billet que vous voulez et venez profiter de l'expérience complète"])}
    },
    "clase": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cours"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez choisir les jours de cours. Sélectionnez les jours que vous souhaitez"])}
    },
    "alquiler": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locations"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez si vous voulez louer du matériel"])}
    },
    "greenFee": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Green Fee"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez si vous souhaitez un Green Fee."])}
    },
    "relax": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relax"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez si vous souhaitez un service de relaxation."])}
    },
    "personal": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnel"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez le personnel."])}
    },
    "parking": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parking"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez votre parking préféré"])}
    },
    "mascota": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animal de compagnie"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez les services pour animaux de compagnie"])}
    },
    "competicion": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compétition"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez la compétition"])}
    },
    "tooltipMessageIsNominal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certains services sont nominatif, ce qui signifie qu'un seul service peut être sélectionné par personne."])},
    "servicioNominal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service nominatif"])},
    "noDisponibilidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune disponibilité supplémentaire pour ce service"])},
    "maximoPermitido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez atteint le nombre maximum de services par personne"])}
  },
  "trabajaNosotros": {
    "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travaillez avec Nous"])}
  },
  "titulos": {
    "completarReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remplissez les informations suivantes pour continuer votre réservation"])},
    "editarPerfil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le profil"])},
    "itinerario": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Itinéraire"])},
    "extras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extras"])},
    "incluye": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incluides"])},
    "motivosCancelacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motifs d'annulation"])},
    "seleccionServicios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réservez pour autant de personnes que vous le souhaitez"])}
  },
  "cardReservaPlazasLibres": {
    "descripcion": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous avez encore ", _interpolate(_named("plazasLibres")), " places disponibles dans votre logement, ce qui correspond à un paiement en attente de ", _interpolate(_named("pagoPendiente")), ". Vous avez deux options :"])},
    "opcionUno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partager le lien pour compléter les places disponibles."])},
    "opcionDos": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Payer le montant en attente de ", _interpolate(_named("pagoPendiente")), " parmi les personnes confirmées."])},
    "cancelacion": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Si aucune des deux options n'est effectuée avant le ", _interpolate(_named("fecha")), ", la ", _interpolate(_named("final"))])},
    "cancelacionBold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["réservation sera automatiquement annulée."])},
    "msjPlazaLibresPagadas": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous avez des ", _interpolate(_named("plazasLibres")), " places libres, partagez le lien pour les compléter et le supplément « Places libres » sera recalculé en votre faveur."])},
    "button": {
      "abonarPago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payer le montant en attente"])},
      "copiarLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copier le lien"])}
    }
  },
  "langs": {
    "es": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espagnol"])},
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anglais"])},
    "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Française"])},
    "it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italien"])},
    "ca": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catalan"])}
  }
}